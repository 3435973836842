import { useQuery } from "react-query";

import ApiClient from "../../../utils/apiClient";
import { getFeedersApiUrl, getFeedersApiUrlByExtent } from "../../../api";
import { logError } from "../../../services";
import { useFeederStore, useMapStore } from "../../../stores";
import { mapFeedersResponse } from "../utils/MapFeeders.utils";
import { FeedersResponse } from "../types/FeedersResponse";
import { useExtentParams } from "../../../hooks";
import { isGeoJSONEmpty } from "../../../utils/mapUtils";
import { useDebounce } from "../../../hooks/useDebounce";

export const FEEDER_KEY = "feeders";

export const useFeedersByExtent = () => {
  const params = useExtentParams();
  const debouncedParams = useDebounce(params, 500);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);

  const { setData } = useFeederStore((store) => store.actions);

  return useQuery(
    [FEEDER_KEY, debouncedParams],
    () =>
      ApiClient.get<FeedersResponse>(
        debouncedParams !== null
          ? getFeedersApiUrlByExtent({ ...debouncedParams, zoomLevel: Math.round(currentZoomLevel) })
          : getFeedersApiUrl()
      ).then((response) => response.data),
    {
      enabled: debouncedParams !== null,
      onError: (e) => logError(e),
      select: mapFeedersResponse,
      onSuccess: setData,
    }
  );
};

export const useFeeders = () => {
  const feeders = useFeederStore((store) => store.feedersGeoJson);
  const { setData } = useFeederStore((store) => store.actions);

  return useQuery(
    FEEDER_KEY,
    () => ApiClient.get<FeedersResponse>(getFeedersApiUrl()).then((response) => response.data),
    {
      enabled: !feeders || isGeoJSONEmpty(feeders),
      onError: (e) => logError(e),
      select: mapFeedersResponse,
      onSuccess: setData,
    }
  );
};

export default useFeeders;
