import create from "zustand";
import { PlanDto } from "../../planning/types";
import { ProgramsForPlan, WorkOrderPreviewItem } from "../types";

export interface IPlanningStore {
  plans: PlanDto[];
  selectedPlan: PlanDto | undefined;
  selectedProgram: ProgramsForPlan | undefined;
  programsForPlan: Record<string, ProgramsForPlan[]> | undefined;
  setSelectedPlan: (plan: PlanDto | undefined) => void;
  setSelectedProgram: (program: ProgramsForPlan | undefined) => void;
  setPlans: (plans: PlanDto[]) => void;
  setProgramsForPlan: (planId: string, programs: ProgramsForPlan[]) => void;
  setWorkOrderForProgramPreview: (planId: string, programId: string, circuits: WorkOrderPreviewItem[]) => void;
}

export const usePlanningStore = create<IPlanningStore>((set, get) => ({
  plans: [],
  selectedPlan: undefined,
  selectedProgram: undefined,
  programsForPlan: undefined,
  setPlans: (plans) => set({ plans }),
  setSelectedPlan: (plan) => set({ selectedPlan: plan }),
  setSelectedProgram: (program) => set({ selectedProgram: program }),
  setProgramsForPlan: (planId, programs) => set({ programsForPlan: { ...get().programsForPlan, [planId]: programs } }),
  setWorkOrderForProgramPreview: (planId, programId, circuits) => {
    const programsForPlan = get()?.programsForPlan;
    const programForPlan = programsForPlan?.[planId];

    const programIndex = programForPlan?.findIndex((program) => program.programId === programId) ?? -1;

    const program = programForPlan?.[programIndex];

    if (!program) {
      return;
    }

    program.nodes?.forEach((node) => {
      const circuitForNode = circuits.find((circuit) => circuit.taxonomyNodeId === node.taxonomyNodeId);
      node.spans = circuitForNode?.spans;
    });

    programForPlan?.splice(programIndex, 1, program);

    set({ programsForPlan: { ...programsForPlan, [planId]: programForPlan } });
  },
}));
