export const LAYERS_MENU_WIDTH = 359;

export const LEFT_SIDEBAR_WIDTH = 331;

export const RIGHT_SIDEBAR_WIDTH = 359;

export const TOP_HEADER_HEIGHT = 48;

export const STAR_COMMAND_LHS_WIDTH = 385;

export const STAR_COMMAND_RHS_WIDTH = 359;
