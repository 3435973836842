import { useQuery } from "react-query";
import { getRatesSheetUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";
import { ISheetItem, RatesSheetResponse } from "../types";
import { getItem } from "../utils";

const extendRatesSheetData = (data: RatesSheetResponse) => {
  if (data.targetObjects.length === 0 || data.vectorObjects.length === 0) {
    return { ...data, ratesSheet: [] as ISheetItem[] };
  }

  return {
    ...data,
    ratesSheet: data.targetObjects.map((tObject) => {
      return {
        ...tObject,
        dynamicValues: data.vectorObjects.map((vObj) => {
          const item = getItem(data.items, vObj.id, tObject.id);
          if (item) {
            return {
              id: item?.id as string,
              vectorId: vObj.id,
              rate: item?.rate ?? 0,
              overtimeRate: item?.overtimeRate ?? 0,
            };
          }

          return {
            vectorId: vObj.id,
            rate: 0,
            overtimeRate: 0,
          };
        }),
      };
    }) as ISheetItem[],
  };
};

const getRatesSheet = (typeId: string | undefined, vendorId?: string) => {
  return typeof typeId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : ApiClient.get<RatesSheetResponse>(getRatesSheetUrl(typeId, vendorId)).then((response) =>
        extendRatesSheetData(response.data)
      );
};

export const useRatesSheet = (typeId: string | undefined, vendorId?: string) => {
  return useQuery(["rates-sheet", typeId, vendorId], () => getRatesSheet(typeId, vendorId), {
    enabled: Boolean(typeId),
    onError: logError,
  });
};

export default useRatesSheet;
