import { useMutation } from "react-query";
import { getWorkOrderPreviewUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";
import { WorkOrderBulkDto } from "../types";

const useWorkOrderBulkCreate = () => {
  return useMutation(
    ({ programId, woData }: { programId: string; woData?: Partial<WorkOrderBulkDto> }) =>
      ApiClient.post(getWorkOrderPreviewUrl(programId), woData),
    {
      onSuccess: (_, variables) => {},
      onError: logError,
    }
  );
};

export default useWorkOrderBulkCreate;
