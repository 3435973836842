// Custom Tooltip logic in order to implement show/hide behavior on mouse move
import L, { Point, Tooltip } from "leaflet";
import { useCallback, useEffect, useRef } from "react";

const DEFAULT_OFFSET = new L.Point(10, 1);

export const useMapTooltip = ({
  layer,
  showDelay = 500,
  offset = DEFAULT_OFFSET,
}: {
  layer: L.Map | null;
  showDelay?: number;
  offset?: Point;
}) => {
  const tooltipRef = useRef<Tooltip | undefined>(undefined);
  const tooltipTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const closeTooltip = useCallback(() => {
    tooltipRef.current && layer?.closeTooltip(tooltipRef.current);
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
      tooltipTimeoutRef.current = undefined;
    }
  }, [layer]);

  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        closeTooltip();
      }
    };
  }, [closeTooltip]);

  const onMouseMove = useCallback(
    (e: L.LeafletMouseEvent, tooltipContent: Nullable<string>) => {
      closeTooltip();
      if (!tooltipContent || !layer) {
        return;
      }
      tooltipTimeoutRef.current = setTimeout(() => {
        tooltipTimeoutRef.current = undefined;
        tooltipRef.current = new L.Tooltip().setLatLng(e.latlng).addTo(layer).bindTooltip(tooltipContent, {
          permanent: true,
          offset,
          className: "leaflet-map-tooltip",
        });
      }, showDelay);
    },
    [closeTooltip, layer, offset, showDelay]
  );

  return {
    onMouseMove,
    onMouseOut: closeTooltip,
    closeTooltip,
  };
};
