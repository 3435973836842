import { useMemo } from "react";
import { Props } from "react-select";
import { Option } from "../../../../components/Select";
import { PlanDto } from "../../../planning/types";
import { YearSelect } from "./PlanYearSelect.styled";

type PlanYearSelectProps = Omit<Props, "options"> & {
  plan?: PlanDto;
};

function range(size: number, startAt: number = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

const PlanYearSelect = ({ plan, value, onChange, ...props }: PlanYearSelectProps) => {
  const yearOptions = useMemo(() => {
    if (!plan) return [];

    const startYear = new Date(plan.start).getFullYear();
    const endYear = new Date(plan.end).getFullYear();

    const options = range(endYear - startYear + 1, startYear).map(
      (item): Option => ({
        value: `${item}`,
        label: `${item}`,
      })
    );

    return options;
  }, [plan]);

  const selectedOption = useMemo(() => yearOptions.find((option) => option.value === value), [value, yearOptions]);

  return (
    <YearSelect
      width="80px"
      {...props}
      options={yearOptions}
      value={selectedOption}
      onChange={(option: Option) => onChange?.(option.value)}
    />
  );
};

export default PlanYearSelect;
