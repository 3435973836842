import { toLocalDate } from "../../utils/dateUtils";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { Plan } from "../planning/types";

export const sortByDate = <T extends Record<Key, string>, Key extends keyof T>(a: T, b: T, dateType: Key) => {
  const dateA = new Date(a[dateType]).getTime();
  const dateB = new Date(b[dateType]).getTime();

  return dateA < dateB ? -1 : 1;
};

export const getOptionsFromString = (statuses: string[] | undefined) =>
  statuses?.map((item) => ({ value: item, label: capitalizeFirstLetter(item) })) ?? [];

export const getOptionsFromArray = <T extends Record<Key, unknown>, Key extends keyof T>(
  array: T[] | undefined,
  labelKey: Key,
  valueKey: Key
) => {
  return (
    array?.map((item) => {
      return { value: String(item[valueKey]), label: String(item[labelKey]) };
    }) ?? []
  );
};

export const convertPlanDates = (planData: Plan): Plan => {
  const startDate = toLocalDate(planData.planDetails.start, "yyyy-MM-dd");
  const endDate = toLocalDate(planData.planDetails.end, "yyyy-MM-dd");

  return {
    ...planData,
    planDetails: {
      ...planData.planDetails,
      start: startDate,
      end: endDate,
    },
  };
};
