import styled from "styled-components";
import { space } from "styled-system";

export const Loader = styled.div`
  ${space}
  & {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--colors-eastern-blue);
    color: var(--colors-eastern-blue);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--colors-eastern-blue);
    color: var(--colors-eastern-blue);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--colors-eastern-blue);
    color: var(--colors-eastern-blue);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--colors-eastern-blue);
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
`;
