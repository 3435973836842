import styled from "styled-components";
import { boxShadow, BoxShadowProps } from "styled-system";

export const CheckboxInput = styled.input`
  position: absolute;
  appearance: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-family: Barlow !important;
  font-weight: 500;
  font-size: 13px;
`;

export const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  stroke: ${(props) => props.theme.colors.frenchGray};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  fill: ${(props) => props.theme.colors.teal3};
  & input[type="checkbox"]:hover {
    cursor: pointer;
  }
  & input[type="checkbox"]:disabled {
    cursor: not-allowed;
  }
  }
  & input[type="checkbox"]:hover ~ svg.checkbox-unchecked rect {
    stroke: ${(props) => props.theme.colors.teal3};
  }
  & input[type="checkbox"]:checked ~ svg.checkbox-unchecked {
    fill: ${(props) => props.theme.colors.teal3};
    stroke: ${(props) => props.theme.colors.teal3};
  }
  & input[type="checkbox"] ~ svg.checkbox-checked {
    display: none;
  }
  & input[type="checkbox"]:checked ~ svg.checkbox-unchecked {
    display: none;
  }
  & input[type="checkbox"]:checked ~ svg.checkbox-checked {
    display: block;
    fill: ${(props) => props.theme.colors.teal3};
  }
  & input[type="checkbox"]:hover ~ svg.checkbox-checked rect {
    fill: #20949e;
  }

  .checkbox__text {
    margin-left: 5px;
  }
`;

export const CheckboxText = styled.span<{ hasAdditionalInfo?: boolean }>`
  color: ${(props) => props.theme.colors.riverBad};
  display: flex;
  flex-direction: column;
  padding-left: 0px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CheckboxImage = styled.img<BoxShadowProps & { disabled?: boolean }>`
  margin: 0 10px;
  border-radius: 5px;
  background-color: #e6f4f8;
  width: 30px;
  height: 30px;
  ${boxShadow}
  ${(props) => {
    if (props.disabled) {
      return `filter: grayscale(100%);`;
    }
  }};
`;

export const CheckboxAdditionalText = styled.span`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  color: var(--colors-manatee);
  padding-left: 0px !important;
  font-size: 13px;
`;
