import { useHistory } from "react-router-dom";
import { useTaxonomyStore } from "../features/taxonomy/stores";
import useFeederSegmentStore from "../features/layers/stores/FeederSegmentStore";
import { useMapStore, useSpansStore } from "../stores";
import { getForecastRoute } from "../routes";
import {
  getSearchParams,
  resetToLeftMenuSearchParams,
  setFeederIdParam,
  setRegionIdParam,
  setSpanIdParam,
  setVisibleDrawerParam,
} from "../utils/navigationUtils";

export const useSelectOnMap = () => {
  const history = useHistory();
  const setSelectedSpanById = useSpansStore((store) => store.setSelectedSpanById);
  const feedersSegmentGeoJSON = useFeederSegmentStore((store) => store.geoJSON);
  const setFeederSegment = useFeederSegmentStore((store) => store.setSelectedFeederSegmentById);
  const selectedRegion = useTaxonomyStore((store) => store.currentRegion);
  const setLatestObjectIdSelectedForMapAnimation = useMapStore(
    (store) => store.setLatestObjectIdSelectedForMapAnimation
  );

  const selectSpan = (id: string) => {
    const queryParams = getSearchParams();
    const selectedFeederSegment = feedersSegmentGeoJSON?.features.find((item) => item.properties.spanId === id);
    if (!selectedFeederSegment || !selectedRegion) {
      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
      return;
    }

    setSelectedSpanById(id);
    setFeederSegment(selectedFeederSegment?.properties.id);
    setLatestObjectIdSelectedForMapAnimation(id);
    resetToLeftMenuSearchParams(queryParams);

    /*
     * set url in format
     * regionId > feederId > spanId > visibleDrawer
     */
    setRegionIdParam({ params: queryParams, id: selectedRegion });
    setFeederIdParam({
      params: queryParams,
      id: selectedFeederSegment?.properties.feederId,
    });
    setSpanIdParam({ params: queryParams, id: id });
    setVisibleDrawerParam({ params: queryParams, visible: true });

    history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
  };

  return { selectSpan };
};
