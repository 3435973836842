import { Feature, Polygon, Properties } from "@turf/turf";

export enum RISK_PRIORITY {
  VIOLATION = "VIOLATION",
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export type RiskPriority = {
  priority: RISK_PRIORITY;
  score: number;
};

export type ThreatDto = {
  threat: Feature<Polygon, Properties>;
};
