import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { ControlledSelect } from "../../../../components/Select";

export const Content = styled(StyledFlex)`
  --spacer: 16px;
  flex-direction: column;
  padding: var(--spacer);
  gap: var(--spacer);

  label {
    font-weight: 600;
    font-size: 14px;
    color: var(--colors-slate-600);
  }
`;

export const Footer = styled(StyledFlex)`
  --spacer: 16px;
  position: relative;
  padding: var(--spacer);
  gap: var(--spacer);

  > * {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &:before {
    content: "";
    display: inline-block;
    height: 1px;
    background-color: var(--colors-slate-200);
    position: absolute;
    top: 0;
    left: var(--spacer);
    right: var(--spacer);
  }
`;

export const FormGroup = styled(StyledFlex)`
  flex-direction: column;
  gap: 5px;
`;

export const WorksheetSelect = styled(ControlledSelect)`
  .react-select__value-container,
  .react-select__control {
    height: auto;
  }
`;
