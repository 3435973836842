import { area } from "@turf/turf";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { MaintenanceAreaGeometryDto, RISK_PRIORITY } from "../../types/responses";
import { AvailableMaxarImage, RefreshFilterType, StarCommandNode } from "./types";

export const SORT_ORDER = {
  [RISK_PRIORITY.VIOLATION]: 1,
  [RISK_PRIORITY.CRITICAL]: 2,
  [RISK_PRIORITY.HIGH]: 3,
  [RISK_PRIORITY.MEDIUM]: 4,
  [RISK_PRIORITY.LOW]: 5,
};

export const sortByPriority = (a: StarCommandNode, b: StarCommandNode) => {
  // put item with priority = null at the end of the list
  if (a.priority === null && b.priority) return 1;

  if (SORT_ORDER[a.priority] > SORT_ORDER[b.priority]) return 1;

  return -1;
};

export const sortByPriorityDesc = (a: StarCommandNode, b: StarCommandNode) => {
  if (a.priority === null && b.priority) return -1;

  if (SORT_ORDER[a.priority] > SORT_ORDER[b.priority]) return -1;

  return 1;
};

export const getShortName = (name: string) => {
  if (name.length < 4) {
    return name;
  }
  //122-12323i129333 => 122-...33
  return `${name.substring(0, 4)}...${name.substring(name.length - 2, name.length)}`;
};

export const getNumberOfCheckedImages = (images: AvailableMaxarImage[]) => {
  if (!images?.length) {
    return 0;
  }
  return images.filter((image) => image.checked)?.length ?? 0;
};

export const areFiltersInvalid = (filters: RefreshFilterType) => {
  const { dateRange, seasonalFilter, ageCategories } = filters;

  const isageCategoriesInvalid = !ageCategories || ageCategories.length === 0;
  const isSeasonalFilterInvalid =
    seasonalFilter?.seasons === undefined || seasonalFilter?.seasons.length === 0 || seasonalFilter?.year === undefined;
  const isDateRangeInvalid =
    dateRange?.maxDate === undefined ||
    dateRange?.minDate === undefined ||
    dateRange?.maxDate === "" ||
    dateRange?.minDate === "";

  if (isDateRangeInvalid && isSeasonalFilterInvalid && isageCategoriesInvalid) return true;
  return false;
};

export const customAoiToMA = (aoi: Feature<Geometry, GeoJsonProperties>) => {
  if (aoi.geometry.type !== "Polygon") {
    return;
  }

  const maintenanceArea: MaintenanceAreaGeometryDto = {
    id: aoi.properties?.id,
    area: area(aoi) / 10e6,
    polygon: {
      type: "Polygon",
      coordinates: aoi.geometry.coordinates,
    },
  };

  return maintenanceArea;
};
