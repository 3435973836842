import { useMutation } from "react-query";
import { getAddToPlanUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";

interface AddToPlanRequest {
  nodes: { taxonomyNodeId: string; year: string | null }[];
}

export const useAddToPlan = () => {
  return useMutation(
    ({ programId, data }: { programId: string; data: AddToPlanRequest }) =>
      ApiClient.post(getAddToPlanUrl(programId), data),
    {
      onError: logError,
    }
  );
};
