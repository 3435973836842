import { Feature, Point } from "geojson";
import { LatLngBounds } from "leaflet";

import { DEFAULT_ZOOM_LEVEL } from "../../stores/MapStore";
import { ImagerySource, ImagerySourcesResponse } from "../../types/responses";
import { EncroachmentResponseProperties } from "../../features/layers/types/EncroachmentsResponse";
import { workTypeMap } from "./MapScreen.constants";

import aerial_spray from "../../assets/images/work_types/aerial_spray.svg";
import creek from "../../assets/images/work_types/creek.svg";
import excavator_pushout from "../../assets/images/work_types/excavator_pushout.svg";
import excavator from "../../assets/images/work_types/excavator.svg";
import fence from "../../assets/images/work_types/fence.svg";
import ground_spray from "../../assets/images/work_types/ground_spray.svg";
import mow_pushout from "../../assets/images/work_types/mow_pushout.svg";
import manual_trim from "../../assets/images/work_types/manual_trim.svg";
import small_mower from "../../assets/images/work_types/small_mower.svg";
import tree_rem_large_rural from "../../assets/images/work_types/tree_rem_large_rural.svg";
import tree_rem_large_urban from "../../assets/images/work_types/tree_rem_large_urban.svg";
import tree_rem_small_rural from "../../assets/images/work_types/tree_rem_small_rural.svg";
import tree_rem_small_urban from "../../assets/images/work_types/tree_rem_small_urban.svg";
import tree_rem_ash from "../../assets/images/work_types/tree_remove_ash.svg";
import tree_remove_hazard from "../../assets/images/work_types/tree_remove_hazard.svg";
import vine_rem from "../../assets/images/work_types/vine_rem.svg";
import work_type_placeholder from "../../assets/images/work_placeholder_icon.svg";

import aerial_spray_marker from "../../assets/images/work_types/areal_spray_marker.svg";
import creek_marker from "../../assets/images/work_types/creek_marker.svg";
import excavator_pushout_marker from "../../assets/images/work_types/excavator_pushout_marker.svg";
import excavator_marker from "../../assets/images/work_types/excavator_marker.svg";
import fence_marker from "../../assets/images/work_types/fence_marker.svg";
import ground_spray_marker from "../../assets/images/work_types/ground_spray_marker.svg";
import mow_pushout_marker from "../../assets/images/work_types/mow_pushout_marker.svg";
import manual_trim_marker from "../../assets/images/work_types/manual_trim_marker.svg";
import small_mower_marker from "../../assets/images/work_types/small_mower_marker.svg";
import tree_rem_large_rural_marker from "../../assets/images/work_types/tree_rem_large_rural_marker.svg";
import tree_rem_large_urban_marker from "../../assets/images/work_types/tree_rem_large_urban_marker.svg";
import tree_rem_small_rural_marker from "../../assets/images/work_types/tree_rem_small_rural_marker.svg";
import tree_rem_small_urban_marker from "../../assets/images/work_types/tree_rem_small_urban_marker.svg";
import tree_rem_ash_marker from "../../assets/images/work_types/tree_rem_ash_marker.svg";
import tree_remove_hazard_marker from "../../assets/images/work_types/tree_rem_hazard_marker.svg";
import vine_rem_marker from "../../assets/images/work_types/vine_marker.svg";
import work_type_placeholder_marker from "../../assets/images/work_types/manual_added_marker.svg";
import bucket from "../../assets/images/work_types/bucket.png";
import mow from "../../assets/images/work_types/mow.png";
import full_vm from "../../assets/images/work_types/full-vm.png";
import no_work from "../../assets/images/work_types/no-work.png";
import spray from "../../assets/images/work_types/spray.png";

export type LabelPoint = {
  position: Feature<Point>;
  angle?: number | null;
};

export type EncroachmentsBySpan = Record<
  string,
  {
    encroachments: GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties>[];
    center: Nullable<L.LatLngExpression>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }
>;

export type EncroachmentPositions = Record<
  string,
  {
    center: Nullable<L.LatLngExpression>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }
>;

export const mapImagerySourcesData = (data: ImagerySourcesResponse): Record<string, ImagerySource> => {
  if (!data || !data.sources) {
    return {};
  }

  return data.sources.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as Record<string, ImagerySource>);
};

export const computeExtentFetchData = ({
  currentMapBounds,
  currentZoomLevel,
}: {
  currentMapBounds: Nullable<LatLngBounds>;
  currentZoomLevel: number;
}) => ({
  zoomLevel: Math.round(currentZoomLevel) || DEFAULT_ZOOM_LEVEL,
  minLatitude: Number(
    Math.min(currentMapBounds?.getNorthEast().lat || 0, currentMapBounds?.getSouthWest().lat || 0).toFixed(6)
  ),
  maxLatitude: Number(
    Math.max(currentMapBounds?.getNorthEast().lat || 0, currentMapBounds?.getSouthWest().lat || 0).toFixed(6)
  ),
  minLongitude: Number(
    Math.min(currentMapBounds?.getNorthEast().lng || 0, currentMapBounds?.getSouthWest().lng || 0).toFixed(6)
  ),
  maxLongitude: Number(
    Math.max(currentMapBounds?.getNorthEast().lng || 0, currentMapBounds?.getSouthWest().lng || 0).toFixed(6)
  ),
});

export const getWorkTypeImage = (workTypeCode: string) => {
  return getImageWorkType(workTypeCode, true);
};

export const getWorkTypeColor = (workTypeCode: string) => {
  if (workTypeCode in workTypeMap) {
    return workTypeMap[workTypeCode].color;
  }

  return workTypeMap["placeholder"].color;
};

export const getWorkTypePriority = (workTypeCode: string) => {
  if (workTypeCode in workTypeMap) {
    return workTypeMap[workTypeCode].priority;
  }

  return workTypeMap["placeholder"].priority;
};

export const getWorkTypeMarker = (workTypeCode: string) => {
  return getImageWorkType(workTypeCode, true);
};

const getImageWorkType = (workTypeCode: string, isMarker: boolean) => {
  switch (workTypeCode) {
    case "excavator-existing":
      return isMarker ? excavator_marker : excavator;
    case "excavator-pushout":
      return isMarker ? excavator_pushout_marker : excavator_pushout;
    case "manual-trim":
      return isMarker ? manual_trim_marker : manual_trim;
    case "mow-existing":
      return isMarker ? small_mower_marker : small_mower;
    case "spray-high":
      return isMarker ? aerial_spray_marker : aerial_spray;
    case "spray-low":
      return isMarker ? ground_spray_marker : ground_spray;
    case "tree-removal-ash":
      return isMarker ? tree_rem_ash_marker : tree_rem_ash;
    case "tree-removal-hazard":
      return isMarker ? tree_remove_hazard_marker : tree_remove_hazard;
    case "tree-removal-large-rural":
      return isMarker ? tree_rem_large_rural_marker : tree_rem_large_rural;
    case "tree-removal-large-urban":
      return isMarker ? tree_rem_large_urban_marker : tree_rem_large_urban;
    case "tree-removal-small-rural":
      return isMarker ? tree_rem_small_rural_marker : tree_rem_small_rural;
    case "tree-removal-small-urban":
      return isMarker ? tree_rem_small_urban_marker : tree_rem_small_urban;
    case "vine-removal":
      return isMarker ? vine_rem_marker : vine_rem;
    case creek:
      return isMarker ? creek_marker : creek;
    case fence:
      return isMarker ? fence_marker : fence;
    case "mow-pushout":
      return isMarker ? mow_pushout_marker : mow_pushout;
    case "full-vm":
      return full_vm;
    case "bucket":
      return bucket;
    case "mow":
      return mow;
    case "no-work":
      return no_work;
    case "spray":
      return spray;
    default:
      return isMarker ? work_type_placeholder_marker : work_type_placeholder;
  }
};
