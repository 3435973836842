import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { crewsUrlV2 } from "../api";
import { logError } from "../services";
import { CrewConfigurationV2 } from "../types/responses/Crews";

export const useCrewsV2 = () => {
  return useQuery(
    "crews-v2",
    () => ApiClient.get<CrewConfigurationV2>(crewsUrlV2).then((response) => response.data.crews),
    {
      onError: (e) => logError(e),
    }
  );
};

export default useCrewsV2;
