import { css } from "styled-components";

export const FontStyle = css`
  font-family: "Barlow";
  font-style: normal;
`;

export const Head1 = css`
  ${FontStyle}
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--colors-river-bad);
`;

export const Head2 = css`
  ${FontStyle}
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  color: var(--colors-blue-chill);
`;

export const Data1 = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 13px;
  color: var(--colors-outer-space);
`;

export const Data2 = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 14px;
  color: var(--colors-outer-space);
`;

export const DataLow = css`
  ${FontStyle}
  font-size: 14px;
  color: var(--colors-nevada);
`;

export const Data3Underline = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 13px;
  text-decoration-line: underline;
  color: var(--colors-eastern-blue);
`;

export const DataLabel = css`
  ${FontStyle}
  font-weight: 500;
  font-size: 11px;
  color: var(--colors-oslo-gray-2);
`;

export const Data3 = css`
  ${FontStyle}
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--colors-oslo-gray-2);
`;

export const InputLabel = css`
  ${FontStyle}
  font-weight: 500;
  font-size: 14px;
  color: var(--colors-oslo-gray-2);
`;

export const InputText = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 14px;
  color: var(--colors-outer-space);
`;

export const InputTextPassive = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 14px;
  color: var(--colors-silver);
`;

export const Link = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 13px;
  text-decoration-line: underline;
  color: var(--colors-eastern-blue);
  &:hover {
    color: ${(props) => props.theme.colors.abbey2};
  }
`;

export const DataButton = css`
  ${FontStyle}
  font-weight: 600;
  font-size: 14px;
  color: var(--colors-eastern-blue);
`;

export const FontSM = css`
  ${FontStyle}

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--colors-neutral-500);
`;
