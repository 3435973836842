import React, { FC, SyntheticEvent, useRef, useState, ReactElement } from "react";

import { getWorkTypeImage } from "../../pages/MapScreen/MapScreen.utils";
import {
  WorkTypeCost,
  WorkTypeExtendedName,
  WorkTypeImage,
  WorkTypeLeftSide,
  WorkTypeNameWrapper,
  WorkTypeRightSide,
  WorkTypeWrapper,
  MoreOptions,
  OptionButton,
  Tooltip,
  DeleteWorktypeButton,
  AdditionalLabelText,
  StyledCountInput,
} from "./WorkTypeItem.styled";
import { ReactComponent as CloseIcon } from "./../../assets/images/close.svg";
import { ReactComponent as MoreIcon } from "./../../assets/images/more.svg";
import { toUsdCurrency } from "../../utils/stringUtils";
import { useClickOutside } from "../../hooks";
import { ReactComponent as AiIcon } from "./../../assets/images/AI.svg";
import { ReactComponent as FpIcon } from "./../../assets/images/fp.svg";
import { WorktypeSource } from "../../types/responses";

interface ISlots {
  additionalDescription: React.ReactNode | null;
  countInput: React.ReactNode | null;
}
interface IAdditionalDescription {
  label: string;
  onClick: () => void;
}

interface ICountInput {
  id: string;
  value: number;
  disabled?: boolean;
  onChange: (e: any) => void;
}
interface IWorkTypeComposition {
  AdditionalDescription: React.FC<IAdditionalDescription>;
  CountInput: React.FC<ICountInput>;
}
interface WorkTypeItemProps {
  deleteWorkType?: (workTypeId: string, objectId?: string, manuallyAdded?: boolean, cost?: number) => void;
  cost?: Maybe<number>;
  name: string;
  code?: string;
  id: string;
  objectId?: string;
  manuallyAdded?: boolean;
  isEditMode?: boolean;
  additionalLabel?: string;
  source?: WorktypeSource;
  showOnlyDelete?: boolean;
  children?: ReactElement<IWorkTypeComposition> | ReactElement<IWorkTypeComposition>[];
  assignToNewWorkorder?: (workTypeId: string) => void;
  showMoreOptions?: boolean;
  iconUrl?: string;
}

const AdditionalDescription: FC<IAdditionalDescription> = ({ label, onClick }) => {
  return <AdditionalLabelText onClick={onClick}>{label}</AdditionalLabelText>;
};

const CountInput: FC<ICountInput> = ({ id, value, disabled, onChange }) => {
  return <StyledCountInput type="number" id={id} value={value} onChange={onChange} min="1" disabled={disabled} />;
};

export const WorkTypeItem: FC<WorkTypeItemProps> & IWorkTypeComposition = ({
  deleteWorkType,
  cost,
  name,
  code,
  id,
  objectId,
  source,
  iconUrl,
  assignToNewWorkorder,
  showOnlyDelete = false,
  manuallyAdded = false,
  isEditMode = true,
  additionalLabel = null,
  showMoreOptions = false,
  children,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const moreOptionsRef = useRef(null);
  useClickOutside(moreOptionsRef, () => showOptions && setShowOptions(false));

  const slots: ISlots = {
    additionalDescription: null,
    countInput: null,
  };

  React.Children.forEach(children, (child: ReactElement | undefined) => {
    if (!child) return;
    // Filter acceptable children
    switch (child.type) {
      case WorkTypeItem.AdditionalDescription: {
        slots.additionalDescription = child;
        break;
      }
      case WorkTypeItem.CountInput: {
        slots.countInput = child;
        break;
      }
    }
  });

  const handleDeleteWT = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isEditMode) return;

    deleteWorkType?.(id, objectId, manuallyAdded, cost);
  };

  return (
    <WorkTypeWrapper isEditMode={isEditMode} data-testid={`workType${name}`} {...props}>
      <WorkTypeLeftSide>
        {code && <WorkTypeImage src={iconUrl ?? getWorkTypeImage(code)} width={"28px"} height={"28px"} />}
        <WorkTypeNameWrapper hasAdditionalText={additionalLabel !== null}>
          <WorkTypeExtendedName>
            <div title={name} className="worktype-name">
              {name}
            </div>
          </WorkTypeExtendedName>
          {additionalLabel !== null && <span className="additionalLabel">{additionalLabel}</span>}
          {slots.additionalDescription}
        </WorkTypeNameWrapper>
      </WorkTypeLeftSide>
      <WorkTypeRightSide>
        {!!cost && <WorkTypeCost>{toUsdCurrency(cost)}</WorkTypeCost>}
        {source === "AI" && <AiIcon />}
        {source === "FP" && <FpIcon />}
        {slots.countInput}
        {showOnlyDelete && (
          <DeleteWorktypeButton
            type="button"
            disabled={!isEditMode}
            Icon={CloseIcon}
            onClick={handleDeleteWT}
            title={!isEditMode ? "Click Edit to delete Worktype" : ""}
          />
        )}
        {showMoreOptions && (
          <MoreOptions
            data-testid={`workType${name}Menu`}
            onClick={() => setShowOptions(isEditMode && !showOptions)}
            isEditMode={isEditMode}
            ref={moreOptionsRef}
            className={`${showOptions ? "open" : ""}`}
          >
            <MoreIcon />
            {
              <div>
                {assignToNewWorkorder && (
                  <OptionButton
                    data-testid="assignToNewWorkOrderButton"
                    type="button"
                    onClick={() => assignToNewWorkorder(id)}
                  >
                    Assign to New Work Order
                  </OptionButton>
                )}
                <OptionButton data-testid="deleteWorkTypeButton" type="button" onClick={handleDeleteWT}>
                  Delete
                </OptionButton>
              </div>
            }
            <Tooltip>Click Edit to enable this button</Tooltip>
          </MoreOptions>
        )}
      </WorkTypeRightSide>
    </WorkTypeWrapper>
  );
};

WorkTypeItem.AdditionalDescription = AdditionalDescription;
WorkTypeItem.CountInput = CountInput;
