import { useQuery } from "react-query";
import { rateTypesUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";
import { RateTypesResponse } from "../types";

const getRateTypes = () => ApiClient.get<RateTypesResponse>(rateTypesUrl).then((response) => response.data.rateTypes);

export const useRateTypes = () => {
  return useQuery("rate-types", getRateTypes, {
    onError: logError,
  });
};

export default useRateTypes;
