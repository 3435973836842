import { useQuery } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { logError } from "../../../services";
import { getLiteProgramsUrl } from "../../../api";
import { ProgramsLiteResponse } from "../types";

const fetchLitePrograms = (planId: string = "") => {
  return () => ApiClient.get<ProgramsLiteResponse>(getLiteProgramsUrl(planId)).then((res) => res.data.programs);
};

export const useProgramsLite = (planId: string | undefined) => {
  return useQuery(["programs", planId], fetchLitePrograms(planId), {
    enabled: planId !== undefined,
    onError: logError,
  });
};

export default useProgramsLite;
