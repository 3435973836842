import L from "leaflet";
import { useEffect } from "react";

export const useReDrawingOnEscape = (layer: L.Draw.Polygon | L.Draw.Polyline, features?: any) => {
  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        layer?.disable();
        setTimeout(() => {
          layer?.enable();
        }, 500);
      }
    };
    document.addEventListener("keydown", onEscape, false);
    return () => {
      document.removeEventListener("keydown", onEscape, false);
    };
  }, [layer]);
};
