import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { IconButton } from "../../../../components/IconButton";
import { TableBase } from "../../../../components/Table";
import { FeederCircleStatus } from "../../../taxonomy/components/StatusCircle";
import PlanYearSelect from "../PlanYearSelect";
import TableRowMenu from "./components/TableRowMenu/TableRowMenu";

export const ProgramsTable = styled(TableBase)`
  width: 100%;
  background: none;
  border-collapse: separate;
  color: var(--colors-slate-500);
  border-spacing: 0 12px;
  font-size: 14px;

  * {
    box-sizing: border-box;
  }

  > tbody {
    td {
      background: ${(props) => props.theme.colors.white};

      b {
        text-align: left;
        color: var(--colors-slate-900);
      }

      + td {
        padding: 16px;
      }

      &:last-child {
        text-align: right;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
` as typeof TableBase;

export const CircuitsTable = styled(TableBase)`
  width: 100%;
  border-collapse: collapse;
  color: var(--colors-slate-500);
  font-weight: 600;
  font-size: 12px;
  margin-top: -12px;

  thead {
    height: 48px;

    th {
      background: #eff3f6;
      padding: 12px 16px;
      color: var(--colors-slate-500);

      + th {
        text-align: right;
      }
    }
  }

  tbody {
    tr + tr {
      td {
        border-top: 1px solid var(--colors-slate-200);
      }
    }

    td {
      color: var(--colors-slate-500);
      padding: 16px;

      + td {
        text-align: right;
      }
    }
  }

  tfoot {
    th {
      background-color: var(--colors-white);
      text-align: right;
      vertical-align: middle;
      padding: 16px;
    }
  }
` as typeof TableBase;

export const Expander = styled(StyledFlex)`
  padding: 16px;
  align-items: center;

  svg {
    flex-shrink: 0;
    transition: transform 0.3s ease-in-out;
  }

  &.is-expanded {
    svg {
      transform: rotateZ(180deg);
    }
  }
`;

export const ExpanderText = styled.p`
  color: var(--colors-slate-900);
  margin: 0 0 0 16px;
  font-weight: 600;
  font-family: inherit;
`;

export const CircuitNameContainer = styled(StyledFlex)`
  align-items: center;

  > * + * {
    margin-left: 8px;
  }

  b {
    font-size: 14px;
  }
`;

export const DeleteCircuitButton = styled(IconButton)`
  color: var(--colors-slate-500);
  border: none;
  padding: 0;
`;

export const TableRowMenuStyled = styled(TableRowMenu)`
  display: inline-block;
`;

export const PlanYearSelectStyled = styled(PlanYearSelect)`
  .react-select__option {
    text-align: left;
  }
`;

export const FeederCircleStatusStyled = styled(FeederCircleStatus)`
  display: inline-block;
`;

export const ProgramsEmptyState = styled.p`
  font-style: italic;
  color: var(--colors-gray-500);
  margin: 0;
`;

export const CircuitsEmptyState = styled.p`
  font-style: italic;
  color: var(--colors-gray-500);
  padding: var(--spacer);
  text-align: left;
  margin: 0;
`;
