import { useQuery } from "react-query";
import { getProgramsForPlanUrl, getSingleProgramUrl } from "../../../api";
import ApiClient from "../../../utils/apiClient";
import { usePlanningStore } from "../stores";
import { ProgramsForPlan, ProgramsForPlanResponse } from "../types";

export const PROGRAMS_FOR_PLAN_QUERY_KEY = "programs-for-plan";
export const SINGLE_PROGRAM_QUERY_KEY = "single-program";

const addProgramIdToNodes = (programsForPlan: ProgramsForPlanResponse): ProgramsForPlanResponse => ({
  ...programsForPlan,
  programs: programsForPlan?.programs?.map((program) => ({
    ...program,
    nodes: program?.nodes?.map((node) => ({ ...node, programId: program.programId })),
  })),
});

export const useProgramsForPlan = (planId?: string) => {
  const setProgramsForPlan = usePlanningStore((state) => state.setProgramsForPlan);

  return useQuery<ProgramsForPlanResponse>(
    [PROGRAMS_FOR_PLAN_QUERY_KEY, planId],
    () => ApiClient.get<ProgramsForPlanResponse>(getProgramsForPlanUrl(planId)).then((response) => response.data),
    {
      onSuccess: (data) => (planId ? setProgramsForPlan(planId, data.programs) : null),
      select: addProgramIdToNodes,
      enabled: Boolean(planId),
    }
  );
};

export const useSingleProgram = (programId?: string) => {
  return useQuery(
    [SINGLE_PROGRAM_QUERY_KEY, programId],
    () => ApiClient.get<ProgramsForPlan>(getSingleProgramUrl(programId)).then((res) => res.data),
    {
      enabled: Boolean(programId),
    }
  );
};
