import styled from "styled-components";
import { Select } from "../../../../components/Select";

export const YearSelect = styled(Select)`
  margin-left: auto;

  .react-select__value-container,
  .react-select__control,
  .react-select__indicators {
    height: 24px;
    min-height: 24px;
  }

  .react-select__placeholder,
  .react-select__single-value {
    font-family: "Barlow";
    color: var(--colors-river-bad);

    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;
