import styled from "styled-components";
import { TOOLTIP } from "../../../../../../constants";

export const Wrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  width: 20px;
  background: none;
  border: none;
  outline: none;
  color: var(--colors-slate-500);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
    flex-shrink: 0;
  }

  &:hover {
    color: var(--colors-slate-400);
  }
`;

export const MenuContainer = styled.div`
  position: relative;
`;

export const MenuList = styled.ul<{ visible?: boolean }>`
  width: 160px;
  list-style: none;
  position: absolute;
  top: 10px;
  right: 0;
  padding: 8px 0;
  margin: 0;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  background: var(--colors-white);
  box-shadow: 0px 10px 22px rgba(45, 77, 108, 0.15);
  border-radius: 8px;
  z-index: ${TOOLTIP};
`;

export const MenuItem = styled.li`
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--colors-slate-900);
  font-weight: 400;
  font-size: 14px;
  padding: 0 8px;

  &:hover {
    cursor: pointer;
    color: var(--colors-slate-600);
    background-color: var(--colors-slate-50);
  }
`;
