import { Fragment } from "react";
import { StatusRadio } from "./StatusRadio";

const NUMBER_OF_STATUSES = 13;

export const StatusSkeleton = () => {
  return (
    <Fragment>
      {Array(NUMBER_OF_STATUSES)
        .fill({})
        .map((_, idx) => (
          <StatusRadio key={`skeleton${idx}`} isLoading={true} />
        ))}
    </Fragment>
  );
};
