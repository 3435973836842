import styled from "styled-components";
import {
  StylesProps,
  ButtonStyleProps,
  BorderProps,
  PaddingProps,
  MarginProps,
  margin,
  border,
  padding,
} from "styled-system";

export const Button = styled.button<StylesProps & ButtonStyleProps & BorderProps & PaddingProps & MarginProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: ${({ padding }) => padding || "6px 12px"};
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #e8ecf1;
  border-radius: ${({ borderRadius }) => borderRadius || "5px"};
  color: #e8ecf1;
  background-color: var(--colors-white);
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  text-decoration: none;
  ${border}
  ${padding}
  ${margin}

  &.secondary {
    background: var(--colors-neutral-100);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--colors-neutral-900);
    &:hover {
      background: var(--colors-neutral-200);
    }
    &[disabled] {
      background-color: var(--colors-neutral-100);
      color: var(--colors-neutral-400);
      cursor: not-allowed;
    }
    svg,
    path {
      fill: var(--colors-black);
    }
  }

  &.primary {
    color: var(--colors-white);
    background-color: var(--colors-vantage-green-new);
    &:hover {
      background: var(--colors-primary);
    }
    &[disabled] {
      background-color: var(--colors-neutral-100);
      color: var(--colors-neutral-400);
      cursor: not-allowed;
    }
  }

  &.close {
    width: 30px;
    aspect-ratio: 1;
    padding: 0;

    svg {
      fill: #85919e;
      &:hover {
        fill: var(--colors-eastern-blue);
      }
      &:active {
        fill: var(--colors-white);
      }
    }
  }

  &[disabled] {
    border-color: var(--colors-oslo-gray);
    background-color: var(--colors-oslo-gray);
    cursor: not-allowed;
  }

  &.popupIcon {
    background-color: var(--colors-black);
    border: none;
    border-bottom: 2px solid transparent;
    padding: 5px 2px;
    border-radius: 0;
    svg,
    path {
      fill: #8d8e90;
    }
    &.isSelected {
      border-color: #1ea7b2;

      svg,
      path {
        fill: #1ea7b2;
      }
    }
  }
`;

export const IconImg = styled.img`
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(1);
`;
