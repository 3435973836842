import { useMutation } from "react-query";

import ApiClient from "../../../utils/apiClient";
import { putCompatibleWorkUrl } from "../../../api";

export const UPDATE_CW_MUTATION_KEY = "update-compatible-work";

export const useUpdateCompatibleWork = () => {
  return useMutation(UPDATE_CW_MUTATION_KEY, (item: { id: string; list: string[] }) =>
    ApiClient.put(putCompatibleWorkUrl(item.id), { compatibleWorkTypeIds: item.list })
  );
};
