import React, { FC } from "react";
import { EMPTY_FN } from "../../constants";

import { Label } from "../LayersMenu/LayersMenu.styled";
import { ReactComponent as CheckboxChecked } from "./../../assets/images/checkbox_checked.svg";
import { ReactComponent as PartialChecked } from "./../../assets/images/checkbox-partial-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "./../../assets/images/checkbox_unchecked.svg";
import {
  CheckboxContainer,
  CheckboxImage,
  CheckboxInput,
  CheckboxText,
  CheckboxAdditionalText,
} from "./Checkbox.styled";

interface CheckboxProps {
  label?: string;
  name?: string;
  value?: string;
  defaultChecked?: boolean;
  partialyChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  id: string;
  onClick?: (e: React.FormEvent<HTMLElement | HTMLInputElement>) => void;
  image?: string;
  additionalText?: string;
  boxShadow?: string;
  tooltipText?: string;
  "data-testid"?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  name,
  value,
  checked,
  disabled,
  id,
  onClick,
  image,
  additionalText,
  boxShadow,
  tooltipText,
  children,
  "data-testid": dataTestId,
  partialyChecked = false,
  defaultChecked = false,
  ...props
}) => {
  return (
    <CheckboxContainer {...props} disabled={disabled}>
      <Label htmlFor={value} title={tooltipText ?? ""} data-testid={dataTestId}>
        <CheckboxInput
          checked={checked}
          readOnly
          id={id}
          name={name}
          onClick={onClick}
          type="checkbox"
          value={value}
          disabled={disabled}
          onChange={EMPTY_FN}
          {...props}
        />
        <CheckboxChecked className="checkbox-checked" />
        {partialyChecked ? <PartialChecked /> : <CheckboxUnchecked className="checkbox-unchecked" />}
        {image && (
          <CheckboxImage boxShadow={boxShadow} src={image} alt={"option"} {...props} className="checkbox__image" />
        )}
        <CheckboxText hasAdditionalInfo={!!additionalText} className="checkbox__text">
          {label}
          {additionalText && (
            <CheckboxAdditionalText className="checkbox__additional-text">{additionalText}</CheckboxAdditionalText>
          )}
        </CheckboxText>
      </Label>
      {children}
    </CheckboxContainer>
  );
};
