import { useMemo } from "react";
import { getOptionsFromString } from "../utils";
import { useWorkOrderPriorities } from "./useWorkOrderPriorities";

export const useWorkOrderPriorityOptions = () => {
  const { data } = useWorkOrderPriorities();

  const options = useMemo(() => getOptionsFromString(data), [data]);

  return options;
};
