import { useQuery } from "react-query";
import { materialsUrl } from "../api";
import { logError } from "../services";
import { MaterialsResponse } from "../types/responses";
import ApiClient from "../utils/apiClient";

export const useMaterials = () => {
  return useQuery(
    "regions",
    () => ApiClient.get<MaterialsResponse>(materialsUrl).then((response) => response.data.materials),
    {
      onError: (e) => logError(e),
    }
  );
};
