import { LatLngBounds } from "leaflet";
import { useEffect, useMemo, useState } from "react";

import { computeExtentFetchData } from "../pages/MapScreen/MapScreen.utils";
import { useMapStore } from "../stores";

export const useExtentParams = () => {
  const map = useMapStore((store) => store.map);
  const currentBounds = useMapStore((store) => store.currentMapBounds);
  const zoom = useMapStore((store) => store.currentZoomLevel);

  const [mapParams, setMapParams] =
    useState<Nullable<{ zoomLevel: number; bounds: Nullable<L.LatLngBoundsExpression> }>>(null);

  useEffect(() => {
    if (!map) {
      return;
    }

    setMapParams({ zoomLevel: map.getZoom(), bounds: currentBounds });
  }, [map, currentBounds]);

  useEffect(() => {
    if (!map) {
      return;
    }

    setMapParams({ zoomLevel: zoom, bounds: map.getBounds() });
  }, [map, zoom]);

  const params = useMemo(() => {
    return mapParams
      ? computeExtentFetchData({
          currentZoomLevel: mapParams?.zoomLevel,
          currentMapBounds: mapParams.bounds as LatLngBounds,
        })
      : null;
  }, [mapParams]);

  return params;
};
