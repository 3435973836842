import { useEffect } from "react";

import { useParcelsStore, usePredominatedWorkTypesStore, useLegendManagementStore, LegendStateType } from "../stores";
import { useTreeCanopyStore } from "../features/layers/stores/TreeCanopyStore";

export const useLegendExpandCollapse = () => {
  const isParcelVisible = useParcelsStore((store) => !store.parcelLayerHidden);
  const isWorkTypeVisible = usePredominatedWorkTypesStore((store) => !store.hidden);
  const areTreesVisible = useTreeCanopyStore((store) => store.layerVisible);
  const areDangerTreeVisible = useTreeCanopyStore((store) => store.dangerTreeEnabled);
  const setLegendExpandState = useLegendManagementStore((store) => store.toggleExpand);

  useEffect(() => {
    if (!isParcelVisible) {
      return;
    }
    setLegendExpandState(LegendStateType.PARCEL, true);
  }, [isParcelVisible, setLegendExpandState]);

  useEffect(() => {
    if (!areTreesVisible) {
      return;
    }
    setLegendExpandState(LegendStateType.TREE_RECON, true);
  }, [areTreesVisible, setLegendExpandState]);

  useEffect(() => {
    if (!isWorkTypeVisible) {
      return;
    }
    setLegendExpandState(LegendStateType.WORK_TYPE, true);
  }, [isWorkTypeVisible, setLegendExpandState]);

  useEffect(() => {
    if (!areDangerTreeVisible) {
      return;
    }

    setLegendExpandState(LegendStateType.DANGER_TREE, true);
  }, [areDangerTreeVisible, setLegendExpandState]);
};
