import React, { FC, useEffect, useRef } from "react";
import { Wrapper } from "./Backdrop.styled";
import { useKeyPress } from "../../hooks/useKeyPress";

interface Props {
  isHidden: boolean;
  setIsHidden: () => void;
}

export const Backdrop: FC<Props> = ({ children, isHidden, setIsHidden }) => {
  const escPressed = useKeyPress("Escape");
  const backdropRef = useRef<HTMLDivElement>(null);

  const onBackdropClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLDivElement).classList.contains("backdrop")) {
      setIsHidden();
    }
  };

  useEffect(() => {
    escPressed && setIsHidden();
  }, [escPressed, setIsHidden]);

  if (isHidden) return null;

  return (
    <Wrapper ref={backdropRef} onClick={onBackdropClick} className="backdrop" data-testid="backdrop-test-id">
      {children}
    </Wrapper>
  );
};
