import { useLayoutEffect, useState } from "react";

export const useCustomMapPane = ({ map, name, zIndex }: { map: Nullable<L.Map>; name: string; zIndex: number }) => {
  const [pane, setPane] = useState<Nullable<HTMLElement>>(null);

  useLayoutEffect(() => {
    if (pane) return;

    const paneLayer = map?.createPane(name);
    if (!paneLayer) {
      return;
    }
    paneLayer.style.zIndex = String(zIndex);
    setPane(paneLayer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pane]);

  return { pane };
};
