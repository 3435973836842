import { ReactNode } from "react";
import { Wrapper, Text } from "./IconWithText.styled";

type IconWithTextProps = {
  Icon?: ReactNode;
  children?: ReactNode;
};

export const IconWithText = ({ Icon, children }: IconWithTextProps) => (
  <Wrapper>
    {Icon}
    <Text>{children}</Text>
  </Wrapper>
);

export default IconWithText;
