import { useMutation, useQueryClient } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { logError } from "../../../services";
import { programsUrl } from "../../../api";
import { ProgramDto } from "../types";
import { PROGRAMS_FOR_PLAN_QUERY_KEY } from "./useProgramsForPlan";

export const useCreateProgram = () => {
  const queryClient = useQueryClient();
  return useMutation((program: ProgramDto) => ApiClient.post(programsUrl, program), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["programs"]);
      queryClient.invalidateQueries([PROGRAMS_FOR_PLAN_QUERY_KEY, variables.planId]);
    },
    onError: logError,
  });
};

export default useCreateProgram;
