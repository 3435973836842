import { useMemo } from "react";
import { useWorkOrdersForObject } from "../../work-order/hooks/useWorkOrdersForObject";
import { getOptionsFromArray } from "../utils";

export const useDependencyWorkOrdersOptions = (spanId: string) => {
  const { data: dependencyWOs } = useWorkOrdersForObject(spanId);

  const options = useMemo(() => getOptionsFromArray(dependencyWOs, "id", "id"), [dependencyWOs]);

  return options;
};
