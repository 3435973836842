export const SOMETHING_WENT_WRONG_MESSAGE = "Something went wrong. Please try again!";

export const getDeleteMessage = (param = "") =>
  `Are sure you want to delete this ${param} entry? Note that it might disrupt calculation results if it has been used in any of them.`;

export const INVALID_MIN_MAX_VALUE = "Min value is equal or greater than max value";

export const SPECIAL_CHARS_ARE_NOT_ALLOWED = "Special characters are not allowed!";

export const INVALID_PHONE_NUMBER = "Please enter a valid phone number!";

export const INVALID_EMAIL = "Please enter a valid email address!";

export const ONLY_NUM_AND_LETTERS = "Only letters and number are allowed!";

export const CREATE_PLAN_SUCCESSFULL = "Plan created successfully.";

export const EDIT_PLAN_SUCCESSFULL = "Plan saved successfully.";

export const CREATE_PROGRAM_SUCCESSFULL = "Program created successfully.";

export const CHOOSE_MAINTENANCE_AREA = "You have to select at least one maintenance area or circuit.";

export const COOSE_ACQUISITION_DATE = "You have to select an acquisition date.";

export const NO_AVAILABLE_IMAGES = "There are no available images for the chosen filter!";

export const DISABLED_FILTER_STAR_COMMAND = "Please select an AOI between 1km² and 100km².";

export const WRONG_IMAGE_AGE_CATEGORIES = "Categories must be selected in a sequence.";

export const PROGRAM_REMOVED = "Program successfully removed.";

export const CIRCUIT_REMOVED = "Circuit successfully removed.";

export const WO_CREATED = "Work order created successfully.";

export const WO_UPDATED = "Work order updated successfully.";

export const WO_DELETED = "Work order deleted successfully.";

export const FAILED_TO_LOAD_WO = "Failed to load work order.";
