import { Wrapper } from "./StatusRadio.styled";

interface IProps {
  label?: string;
  value?: number;
  selected?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export const StatusRadio = ({ label, value, selected, isLoading, onClick }: IProps) => {
  if (isLoading) {
    return <Wrapper className={"isLoading"} />;
  }

  return (
    <Wrapper className={selected ? "isSelected" : ""} onClick={onClick}>
      <span className="label">{label}</span>
      <span className="value">{value}</span>
    </Wrapper>
  );
};
