import { useQuery } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { getTaxonomyItemByLevel } from "../utils";

interface ResponseDto {
  nodes: {
    label: string;
    level: number;
    nodeId: string;
    objectId: string;
  }[];
}

const getTaxonomyNodeParentsUrl = (id?: string) => `/taxonomies/nodes/${id}/parents`;

export const useTaxonomyNodeParents = (id?: string) => {
  return useQuery(
    ["taxonomy-node-parents", id],
    () => ApiClient.get<ResponseDto>(getTaxonomyNodeParentsUrl(id)).then((res) => res.data.nodes),
    {
      enabled: Boolean(id),
      select: (data) => {
        const maxLevel = data.reduce((acc, node) => {
          if (node.level > acc) {
            acc = node.level;
          }

          return acc;
        }, 0);

        // Declare path from parents
        let taxonomyNodeUrl = data.reduce((acc, node) => {
          if (node.level === 1) {
            acc += `regionId=${node.nodeId}`;
            return acc;
          }
          if (node.level === 2) {
            acc += `&feederId=${node.objectId}`;
            return acc;
          }

          acc += `&${getTaxonomyItemByLevel(node.level)}=${node.nodeId}`;
          return acc;
        }, "");

        return { taxonomyNodeUrl, maxLevel };
      },
    }
  );
};
