import create from "zustand";
import { DashboardNodeDto } from "../../reports/types";
import { Row } from "react-table";

export interface IDashboardStore {
  selectedRows: Row<DashboardNodeDto>[];
  setSelectedRows: (rows: Row<DashboardNodeDto>[]) => void;
}

export const useDashboardStore = create<IDashboardStore>((set, get) => ({
  selectedRows: [],
  setSelectedRows: (rows) => set(() => ({ selectedRows: rows })),
}));
