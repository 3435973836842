import { useMemo } from "react";
import { getOptionsFromArray } from "../utils";
import { useEquipmentTypes } from "./useEquipmentTypes";

export const useEquipmentTypeOptions = () => {
  const { data: equipmentTypes } = useEquipmentTypes();

  const options = useMemo(() => getOptionsFromArray(equipmentTypes, "name", "id"), [equipmentTypes]);

  return options;
};
