import { useEffect } from "react";
import L from "leaflet";

export const useCrosshairCursor = (map: Nullable<L.Map>) => {
  useEffect(() => {
    const mapContainer = map?.getContainer();
    if (!mapContainer) return;

    L.DomUtil.addClass(mapContainer, "crosshair-cursor-enabled");

    return () => {
      L.DomUtil.removeClass(mapContainer, "crosshair-cursor-enabled");
    };
  }, [map]);
};
