import { ChangeEvent, FC, useEffect, useState, useRef } from "react";
import { ColumnInterface, Row } from "react-table";
import * as Yup from "yup";

import {
  EditableCellInput,
  CellWrapper,
  RemoveImg,
  ImageWrapper,
  ErrorIcon,
  ErrorMessage,
  EditableCellWrapper,
} from "./EditableCell.styled";
import RemoveImage from "./../../../assets/images/remove.svg";
import { ValidationParams } from "../Table";
import { LoadingState } from "../../../constants";

export interface CustomColumn extends ColumnInterface {
  canRemove: boolean;
  editable?: boolean;
  formatInactive?: (value: string) => string;
  type: string;
  unremovableItemImage?: string;
  validationParams?: ValidationParams;
  regionId?: string;
  dataType?: string;
  name?: string;
  vectorId?: string;
}
interface CellProperties {
  column: CustomColumn;
  initialValue: string;
  removeField: (row: Row, column: CustomColumn, value: string) => void;
  row: Row<Record<string, string>>;
  updateField: (row: Row, column: CustomColumn, value: string) => Promise<LoadingState>;
  validationParams: ValidationParams;
  value: number;
}

export const EditableCell: FC<CellProperties> = ({
  value,
  row,
  column,
  updateField,
  removeField,
  validationParams,
}) => {
  const [targetValue, setTargetValue] = useState("");
  const [inputStateActive, setInputStateActive] = useState(false);
  const [isErrorShown, setShownError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<Nullable<string>>(null);
  const [schemaValidatorObject, setSchemaValidatorObject] = useState(Yup.object().shape({}));
  const inputEl = useRef(null);

  useEffect(() => {
    if (!validationParams) {
      return;
    }
    setSchemaValidatorObject(Yup.object().shape(column.validationParams || validationParams));
  }, [validationParams, column.validationParams]);

  useEffect(() => {
    if (inputStateActive && inputEl.current) {
      (inputEl.current as HTMLInputElement).select();
    }
  }, [inputStateActive, inputEl]);

  const onChange = (e: ChangeEvent) => {
    const inputValue = (e.target as HTMLInputElement).value;
    setShownError(false);
    setValidationMessage(null);
    setTargetValue(inputValue);
    const objForValidation = column.name ? { [column.name]: inputValue } : { value: inputValue };
    schemaValidatorObject.validate(objForValidation).catch((e) => {
      setShownError(true);
      setValidationMessage(e.message);
    });
  };

  const onBlur = () => {
    setInputStateActive(false);
    setShownError(false);
    setValidationMessage(null);
    const objForValidation = column.name ? { [column.name]: targetValue } : { value: targetValue };
    schemaValidatorObject
      .validate(objForValidation)
      .then(() => {
        updateField(row, column, targetValue).then((res) => {
          if (res === LoadingState.Error) {
            //set real value to target
            setTargetValue(value.toString());
          }
        });
      })
      .catch(() => {
        setTargetValue(value.toString());
      });
  };

  const onClickRemoveButton = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    removeField(row, column, targetValue);
  };

  useEffect(() => {
    setTargetValue(String(value));
  }, [value]);

  const isDirtyField =
    row.original.dirtyFields && Array.isArray(row.original.dirtyFields)
      ? row.original.dirtyFields.includes(column.id!)
      : false;

  return (
    <CellWrapper flex={column.canRemove || column.unremovableItemImage ? true : false}>
      <EditableCellWrapper className="editable-input">
        <EditableCellInput
          ref={inputEl}
          isDirty={isDirtyField}
          error={isErrorShown}
          editable={column.editable}
          value={
            (!inputStateActive && column?.formatInactive) || (!column.editable && column?.formatInactive)
              ? column.formatInactive(targetValue)
              : targetValue
          }
          readOnly={!column.editable}
          type={column.type}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={() => setInputStateActive(true)}
          canRemove={column.canRemove}
          name={column.name}
        />
        {isErrorShown && inputStateActive && <ErrorIcon />}
        {validationMessage && <ErrorMessage>{validationMessage}</ErrorMessage>}
      </EditableCellWrapper>
      {column.canRemove && !row.original?.unremovableItem && (
        <ImageWrapper onClick={onClickRemoveButton}>
          <RemoveImg activeInput={inputStateActive} src={RemoveImage} />
        </ImageWrapper>
      )}
      {row.original?.unremovableItem && column.unremovableItemImage && (
        <ImageWrapper>
          <img src={column.unremovableItemImage} alt="unremovable flag" />
        </ImageWrapper>
      )}
    </CellWrapper>
  );
};
