import { subDays } from "date-fns";
import { PREDEFINED_DATE_OPTIONS } from "../../constants";
import { FireEvent, FireSpreadProperties, Mark, ReducerState } from "../../types/WildfireCluster";
import { Feature, Polygon } from "geojson";

export function getDateRangeFromOption(
  selectedOption: (typeof PREDEFINED_DATE_OPTIONS)[number]
): [Date | null, Date | null] {
  let startDate;
  const today = new Date();
  switch (selectedOption.value) {
    case "LastTwoDays": {
      startDate = subDays(today, 2);
      break;
    }
    case "LastSevenDays": {
      startDate = subDays(today, 7);
      break;
    }
    default: {
      startDate = subDays(today, 30);
      break;
    }
  }

  return [startDate, today];
}

type Action =
  | { type: "set_timeframe"; payload: { label: string; value: string } }
  | { type: "set_show_timeline"; payload: boolean }
  | { type: "set_timeline_value"; payload: number }
  | { type: "set_prediction_timeline_value"; payload: number }
  | { type: "set_selected_mark"; payload: Mark | undefined }
  | { type: "set_cluster_hotspots"; payload: FireEvent[] | undefined }
  | { type: "reset_timeline"; payload: Mark | undefined }
  | { type: "set_date_range"; payload: [Date | null, Date | null] }
  | { type: "set_fire_spread"; payload: boolean };

export function reducer(state: ReducerState, action: Action): ReducerState {
  const { type, payload } = action;

  switch (type) {
    case "set_timeframe": {
      return { ...state, timeframe: payload };
    }
    case "set_fire_spread": {
      return { ...state, showFireSpread: payload };
    }
    case "set_show_timeline": {
      return { ...state, showTimeline: payload };
    }
    case "set_selected_mark": {
      return { ...state, selectedMark: payload };
    }
    case "set_timeline_value": {
      return { ...state, timelineValue: payload, selectedClusterHotspots: undefined };
    }
    case "set_prediction_timeline_value": {
      return { ...state, predictionTimelineValue: payload };
    }
    case "set_cluster_hotspots": {
      return { ...state, selectedClusterHotspots: payload };
    }
    case "reset_timeline": {
      return {
        ...state,
        selectedMark: payload,
        timelineValue: 0,
        showFireSpread: false,
        selectedClusterHotspots: undefined,
      };
    }
    case "set_date_range": {
      return { ...state, dateRange: payload };
    }

    default: {
      return state;
    }
  }
}

export const sortByDate = (a: Feature<Polygon, FireSpreadProperties>, b: Feature<Polygon, FireSpreadProperties>) => {
  if (new Date(a.properties.date) > new Date(b.properties.date)) {
    return 1;
  }

  return -1;
};
