import { useEffect, useMemo } from "react";
import { IconWithText } from "./components/IconWithText";
import { PlansDropdown } from "./components/PlansDropdown";
import { Option } from "../../../../components/Select";
import { VantageLogo } from "../../../../components/VantageLogo";
import { PlanDto } from "../../../planning/types";
import { ReactComponent as SumIcon } from "../../../../assets/images/icon_sigma.svg";
import { ReactComponent as PriceIcon } from "../../../../assets/images/icon_dollar_sign.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendar.svg";
import { Wrapper, PlanInfoContainer, StatusBadge, ExitButton } from "./Header.styled";
import { toUsdCurrency } from "../../../../utils/stringUtils";

export const getYear = (yearStr: string) => {
  const date = new Date(yearStr);
  return date && !isNaN(date.getTime()) ? date.getFullYear() : null;
};

type HeaderProps = {
  plans?: PlanDto[];
  selectedPlan?: PlanDto;
  onSelectPlan?: (plan: PlanDto | undefined) => void;
  onExit?: () => void;
  showExitButton?: boolean;
  readOnly?: boolean;
};

export const Header = ({ plans, selectedPlan, showExitButton = true, readOnly, onSelectPlan, onExit }: HeaderProps) => {
  const planOptions = useMemo(
    () => (plans || [])?.map((plan): Option => ({ label: plan.name, value: plan.id })),
    [plans]
  );

  const selectedOption = useMemo(
    () => planOptions.find((option) => option.value === selectedPlan?.id),
    [planOptions, selectedPlan]
  );

  useEffect(() => {
    if (selectedPlan) {
      const updatedPlan = plans?.find((plan) => plan.id === selectedPlan?.id);
      onSelectPlan?.(updatedPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  return (
    <Wrapper>
      <VantageLogo px="16" />
      <div className="delimiter">|</div>
      <PlansDropdown
        value={selectedOption}
        options={planOptions}
        isDisabled={readOnly}
        onChange={(option: Option) => {
          const selectedPlan = plans?.find((plan) => plan.id === option.value);
          onSelectPlan?.(selectedPlan);
        }}
      />
      {selectedPlan ? (
        <PlanInfoContainer>
          <StatusBadge data-status={selectedPlan.status.toLocaleLowerCase()}>{selectedPlan.status}</StatusBadge>
          <IconWithText Icon={<CalendarIcon />}>
            {getYear(selectedPlan.start)} - {getYear(selectedPlan.end)}
          </IconWithText>
          <IconWithText Icon={<SumIcon />}>{selectedPlan.plannedCircuitsCount} circuits</IconWithText>
          <IconWithText Icon={<PriceIcon />}>{toUsdCurrency(selectedPlan.budget)}</IconWithText>
        </PlanInfoContainer>
      ) : null}
      {showExitButton && (
        <ExitButton variant="secondary" onClick={onExit}>
          Exit planning mode
        </ExitButton>
      )}
    </Wrapper>
  );
};

export default Header;
