import styled from "styled-components";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  PositionProps,
  position,
  PaddingProps,
  padding,
  BorderProps,
  border,
  GridProps,
  grid,
  BackgroundProps,
  background,
  BoxShadowProps,
  boxShadow,
} from "styled-system";

const Flex = styled.div<FlexboxProps & { column?: boolean }>`
  display: flex;
  flex-direction: ${(P) => P.column && "column"};
`;

const FlexItem = styled("div")<{ flex?: number }>`
  flex: ${(P) => (P.flex ? `${P.flex}%` : "1")};
`;

const StyledFlex = styled.div<
  FlexboxProps &
    MarginProps &
    PositionProps &
    LayoutProps &
    PaddingProps &
    BorderProps &
    BoxShadowProps &
    BackgroundProps & { gap?: string | number }
>`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : "inherit")};
  ${flexbox};
  ${margin};
  ${layout};
  ${position};
  ${padding};
  ${border};
  ${background}
  ${boxShadow}
`;

const StyledGrid = styled.div<
  GridProps & MarginProps & PositionProps & LayoutProps & PaddingProps & BackgroundProps & { gap?: string | number }
>`
  display: grid;
  gap: ${(props) => (props.gap ? props.gap : "inherit")};
  ${grid};
  ${margin};
  ${layout};
  ${position};
  ${padding};
  ${background}
`;

export { Flex, FlexItem, StyledFlex, StyledGrid };
