import { useEffect, Suspense, lazy } from "react";
import { Switch, Redirect, Route, useLocation, useRouteMatch } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { StompSessionProvider } from "react-stomp-hooks";

import { PrivateRoute } from "./components/PrivateRoute";
import { USER_ROLES } from "./stores/AuthStore";
import {
  landingRoute,
  getForecastRoute,
  getErrorRoute,
  getAdminRoute,
  getStarCommandRoute,
  plansRoute,
  createPlanRoute,
  workOrderRoute,
  planningMode,
  planningModeEdit,
  planningModeCreate,
  planningModeOverview,
  createWorkOrderRoute,
  createAssetWorkOrderRoute,
  viewWorkOrderRoute,
  reportsRoute,
  createReworkWorkOrderRoute,
} from "./routes";
import { logError } from "./services";
import { PublicRoute } from "./components/PublicRoute";
import "./App.css";
import { theme } from "./theme";
import ApiClient from "./utils/apiClient";
import useAuthStore from "./stores/AuthStore";
import { EnvironmentVariablesCheck } from "./components/EnvironmentVariablesCheck";
import { queryClient } from "./reactQueryAppClient";
import { LoadingOverlay } from "./components/LoadingOverlay";

import "@reach/tooltip/styles.css";
import { notificationUrl } from "./api";
import { getEnvValue } from "./get-env";
import { PlanningDashboard } from "./pages/PlanningDashboard";

const Header = lazy(() => import("./components/Header").then((module) => ({ default: module["Header"] })));
const GeneralError = lazy(() => import("./pages/GeneralError").then((module) => ({ default: module["GeneralError"] })));
const AddEditPlan = lazy(() =>
  import("./features/planning/components/AddEditPlan").then((module) => ({ default: module["AddEditPlan"] }))
);
const MapScreen = lazy(() => import("./pages/MapScreen"));
const Admin = lazy(() => import("./pages/Admin"));
const StarCommand = lazy(() => import("./pages/StarCommand"));
const PlanDashboard = lazy(() => import("./pages/PlanDashboard"));
const WorkOrderView = lazy(() => import("./pages/WorkOrder/WorkOrderView"));
const WorkOrderCreate = lazy(() => import("./pages/WorkOrder/WorkOrderCreate"));
const WorkOrderCreateRework = lazy(() => import("./pages/WorkOrder/WorkOrderCreateRework"));
const PlanningMode = lazy(() => import("./pages/PlanningMode"));
const NewPlan = lazy(() => import("./features/planning-mode/components/NewPlan"));
const EditPlan = lazy(() => import("./features/planning-mode/components/EditPlan"));
const PlanningOverview = lazy(() => import("./features/planning-mode/components/PlanningOverview"));
const Reports = lazy(() => import("./pages/Reports"));

const APP_BASE_URL = getEnvValue("REACT_APP_BASE_URL");

// Disable StompJS reconnection attempts by setting
// REACT_APP_DISABLE_STOMP_RECONNECT_TIMEOUT in .env.development.local
const STOMP_RECONNECT_TIMEOUT = getEnvValue("REACT_APP_DISABLE_STOMP_RECONNECT_TIMEOUT") ? 0 : 5000;

function App() {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const setProvider = useAuthStore((store) => store.setProvider);
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();

  useEffect(() => {
    ApiClient.init();
  }, []);

  useEffect(() => {
    setProvider(keycloak);
  }, [setProvider, keycloak]);

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    keycloak.onTokenExpired = () => {
      window.location.reload();
    };
  }, [keycloak]);

  const isForecastPage = getForecastRoute() === pathname;
  const isPlanningModePage = useRouteMatch([planningMode, workOrderRoute]);

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={TopLevelErrorBoundaryFallback}>
        <EnvironmentVariablesCheck />
        <ThemeProvider theme={theme}>
          <StompSessionProvider
            url={`${APP_BASE_URL}${notificationUrl(keycloak?.token)}`}
            reconnectDelay={STOMP_RECONNECT_TIMEOUT}
          >
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<LoadingOverlay />}>
                {!isPlanningModePage ? <Header commentDisabled={!isForecastPage} /> : null}
                <Switch>
                  <PublicRoute path={getErrorRoute()} component={GeneralError} exact />
                  <PrivateRoute path={[getAdminRoute()]} component={Admin} role={USER_ROLES.ADMIN} />
                  <Route key={landingRoute} path={landingRoute} component={PlanningDashboard} exact />
                  <Route path={getForecastRoute()} component={MapScreen} exact />
                  <Route path={plansRoute} component={PlanDashboard} exact />
                  <Route path={createPlanRoute} component={AddEditPlan} exact />
                  <Route path={`${plansRoute}/:id`} component={AddEditPlan} />
                  <Route path={[getStarCommandRoute()]} component={StarCommand} />
                  <Route path={viewWorkOrderRoute} component={WorkOrderView} />
                  <Route path={createWorkOrderRoute} component={WorkOrderCreate} />
                  <Route path={createAssetWorkOrderRoute} component={WorkOrderCreate} />
                  <Route path={createReworkWorkOrderRoute} component={WorkOrderCreateRework} />
                  <Route key={planningModeCreate} component={NewPlan} path={planningModeCreate} />,
                  <Route key={planningModeEdit} component={EditPlan} path={planningModeEdit} />,
                  <Route key={planningModeOverview} component={PlanningOverview} path={planningModeOverview} />,
                  <Route key={planningMode} component={PlanningMode} path={`${planningMode}`} exact />,
                  <Route key={reportsRoute} component={Reports} path={`${reportsRoute}`} exact />,
                  {isAuthenticated && <Redirect to={landingRoute} />}
                </Switch>
              </Suspense>
            </QueryClientProvider>
          </StompSessionProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

const TopLevelErrorBoundaryFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  logError(error);
  resetErrorBoundary();

  return <Redirect to={getErrorRoute()} push={true} />;
};

export default App;
