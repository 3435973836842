import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useCreateProgram } from "../../hooks";
import { Button } from "../../../../components/Button";
import { Option } from "../../../../components/Select";
import { Loader } from "../../../../components/Loader";
import { Input, NumberInput } from "../../../../components/Input/Input";
import { Content, Footer, FormGroup, WorksheetSelect } from "./NewProgramForm.styled";
import { MessageType, useMessageStore } from "../../../../stores";
import { CREATE_PROGRAM_SUCCESSFULL, SOMETHING_WENT_WRONG_MESSAGE } from "../../../../constants";

interface FormData {
  name: string;
  budget: number | undefined;
  worktypes: Option[];
}

const initialValues = {
  name: "",
  budget: undefined,
  worktypes: [],
};

const programSchema = Yup.object().shape({
  name: Yup.string().required("Program name is required."),
  budget: Yup.number().positive("Value must be a positive number.").typeError("Value must be a number."),
  worktypes: Yup.array().min(1, "Choose at least one worktype."),
});

interface IProps {
  closeModal: () => void;
  worktypeOptions: Option[];
  selectedPlanId: string;
}

export const NewProgramForm = ({ closeModal, worktypeOptions, selectedPlanId }: IProps) => {
  const { mutateAsync: createProgram, isLoading } = useCreateProgram();
  const setMessage = useMessageStore((store) => store.setErrorMessage);

  const onSubmit = async (formData: FormData) => {
    const newProgram = {
      name: formData.name,
      budget: Number(formData.budget ?? 0),
      planId: selectedPlanId,
      workTypeIds: formData.worktypes.map((wt) => wt.value),
    };

    try {
      await createProgram(newProgram);
      setMessage(CREATE_PROGRAM_SUCCESSFULL, MessageType.Success);
    } catch {
      setMessage(SOMETHING_WENT_WRONG_MESSAGE);
    } finally {
      closeModal();
    }
  };

  return (
    <Formik<FormData>
      initialValues={initialValues}
      validationSchema={programSchema}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {({ isValid }) => (
        <Form>
          <Content>
            <Input data-testid="programNameInput" name="name" type="string" label="Program Name" id="name" />
            <NumberInput
              data-testid="programBudgetInput"
              id="budget"
              placeholder="0.00"
              name="budget"
              label="Budget"
              prefix="$"
            />
            <FormGroup>
              <label htmlFor="worktypes">Work Types</label>
              <WorksheetSelect
                data-testid="programWorktypes"
                inputId="worktypes"
                placeholder="Select work types"
                name="worktypes"
                isMulti={true}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                allowSelectAll={true}
                options={worktypeOptions}
              />
            </FormGroup>
          </Content>
          <Footer>
            <Button variant="secondary" height="44px" fontSize="16px" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" height="44px" fontSize="16px" disabled={!isValid || isLoading} type="submit">
              {isLoading ? <Loader data-testid="loader" /> : "Create"}
            </Button>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};
