import { useQuery } from "react-query";

import ApiClient from "../../../utils/apiClient";
import { plansUrl } from "../../../api";
import { logError } from "../../../services";
import { PlanDto } from "../types";
import { usePlanningStore } from "../../planning-mode/stores";

export const PLANS_QUERY_KEY = "plans";

interface PlansResponse {
  plans: PlanDto[];
}

const sortPlansByUpdated = (plans: PlanDto[]) => {
  plans.sort((planA, planB) => {
    const aDate = new Date(planA.updated);
    const bDate = new Date(planB.updated);

    return bDate.getTime() - aDate.getTime();
  });

  return plans;
};

export const usePlans = (filters?: { start: Date | null; end: Date | null }) => {
  const setPlans = usePlanningStore((state) => state.setPlans);

  return useQuery(
    [PLANS_QUERY_KEY, filters],
    () => ApiClient.get<PlansResponse>(plansUrl).then((response) => response.data.plans),
    {
      onSuccess: (plans) => setPlans(plans),
      onError: (e) => logError(e),
      select: (plans) => {
        if (filters !== undefined) {
          const filteredPlans = plans
            .filter((plan) => (filters.start === null ? true : filters.start >= new Date(plan.start)))
            .filter((plan) => (filters.end === null ? true : new Date(plan.end) <= filters.end));
          return sortPlansByUpdated(filteredPlans);
        }

        return sortPlansByUpdated(plans);
      },
    }
  );
};

export default usePlans;
