import L from "leaflet";
import { LatLngBounds } from "leaflet";
import { LEAFLET_PREFIX, MAP_BOX_ATTRIBUTION } from "../constants";
import { EncroachmentResponseProperties } from "../features/layers/types/EncroachmentsResponse";
import { getEnvValue } from "../get-env";
import { MAX_ZOOM_LEVEL } from "../pages/MapScreen/MapView/MapView.constants";

import { RegionsResponse } from "../types/responses";
import MapHelper from "./mapHelper";

const ACCESS_TOKEN = getEnvValue("REACT_APP_ACCESS_TOKEN_MAP");
const MAPBOX_SATELLITE_URL = getEnvValue("REACT_APP_MAPBOX_SATELLITE_URL");
const MAPBOX_WHITE_URL = getEnvValue("REACT_APP_MAPBOX_WHITE_MODE_URL");
const MAPBOX_STAR_COMMAND_LIGHT_URL = getEnvValue("REACT_APP_MAPBOX_STAR_COMMAND_LAYER_URL");

export type STATUS_COLOR = "red" | "green" | "yellow";

export const getFeederStatusColor = (count: number): STATUS_COLOR => {
  if (count <= 2) {
    return "green";
  }
  if (count <= 20) {
    return "yellow";
  }

  return "red";
};

export const getRegionStatusColor = (count: number): STATUS_COLOR => {
  if (count <= 5) {
    return "green";
  }
  if (count <= 50) {
    return "yellow";
  }

  return "red";
};

export const sortByCode = (
  a: GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties>,
  b: GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties>
) => {
  if (!a.properties.code || !b.properties.code) {
    return a.properties.name.localeCompare(b.properties.name);
  }
  return Number(a.properties.code) - Number(b.properties.code);
};

export const findRegion = (regionsByParent: Record<string, RegionsResponse[]>, regionId: string | null) => {
  if (!regionId || !regionsByParent) {
    return;
  }

  const keys = Object.keys(regionsByParent);
  for (const key of keys) {
    const region = regionsByParent[key].find((item) => item.feature.properties.id === regionId);
    if (region) {
      return region;
    }
  }

  return null;
};

export const booleanPointInPolygon = (
  point: L.LatLng | L.LatLngLiteral | null | undefined,
  bounds: LatLngBounds | null | undefined
) => MapHelper.isPointInPolygon(point, bounds);

export const isGeoJSONEmpty = <T extends GeoJSON.FeatureCollection>(data: Maybe<Nullable<T>>) => {
  return !data || !data.features || data.features.length === 0;
};

export const satelliteLayer = L.tileLayer(`${MAPBOX_SATELLITE_URL}${ACCESS_TOKEN}`, {
  maxZoom: MAX_ZOOM_LEVEL,
  accessToken: ACCESS_TOKEN,
  id: "mapbox-satellite",
  tileSize: 512,
  zoomOffset: -1,
  attribution: `${LEAFLET_PREFIX} | ${MAP_BOX_ATTRIBUTION}`,
});

export const whiteLayer = L.tileLayer(`${MAPBOX_WHITE_URL}${ACCESS_TOKEN}`, {
  maxZoom: MAX_ZOOM_LEVEL,
  accessToken: ACCESS_TOKEN,
  id: "mapbox",
  tileSize: 512,
  zoomOffset: -1,
  attribution: `${LEAFLET_PREFIX} | ${MAP_BOX_ATTRIBUTION}`,
});

export const starCommandLightLayer = L.tileLayer(`${MAPBOX_STAR_COMMAND_LIGHT_URL}${ACCESS_TOKEN}`, {
  maxZoom: MAX_ZOOM_LEVEL,
  accessToken: ACCESS_TOKEN,
  id: "mapbox",
  tileSize: 512,
  zoomOffset: -1,
  attribution: `${LEAFLET_PREFIX} | ${MAP_BOX_ATTRIBUTION}`,
});
