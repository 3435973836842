import { useQuery } from "react-query";
import { workOrderPrioritiesUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";

export const useWorkOrderPriorities = () => {
  return useQuery(
    "wo-priorities",
    () => ApiClient.get<string[]>(workOrderPrioritiesUrl).then((res) => res.data as string[]),
    {
      onError: (e) => logError(e),
    }
  );
};
