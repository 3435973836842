import { Wrapper } from "./VoltageFilter.styled";
import { NumericFormat } from "react-number-format";
import { ReactComponent as CloseIcon } from "../../../../assets/images/close.svg";
import { Input, Label } from "../../../../components/Input/Input.styled";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { IconButton } from "../../../../components/IconButton";

interface IProps {
  voltageFilters: [number | undefined, number | undefined] | undefined;
  setVoltageFilters: React.Dispatch<React.SetStateAction<[number | undefined, number | undefined] | undefined>>;
  closePopup: () => void;
}
export const VoltageFilter = ({ voltageFilters, setVoltageFilters, closePopup }: IProps) => {
  return (
    <Wrapper flexDirection={"column"}>
      <StyledFlex justifyContent={"space-between"} alignItems={"center"}>
        <span>Voltage</span>
        <IconButton
          data-testid="popupCloseButton"
          Icon={CloseIcon}
          className="close"
          onClick={closePopup}
          border="none"
        />
      </StyledFlex>
      <StyledFlex mt={"20px"} flexDirection={"column"}>
        <Label>Min</Label>
        <NumericFormat
          id={"min"}
          customInput={Input}
          onValueChange={({ value }) => {
            setVoltageFilters((prev) => [
              value === "" ? undefined : Number(value),
              prev !== undefined ? prev[1] : undefined,
            ]);
          }}
          value={voltageFilters?.[0]}
        />
      </StyledFlex>
      <StyledFlex mt={"10px"} flexDirection={"column"}>
        <Label>Max</Label>
        <NumericFormat
          id={"max"}
          customInput={Input}
          onValueChange={({ value }) => {
            setVoltageFilters((prev) => [
              prev !== undefined ? prev[0] : undefined,
              value === "" ? undefined : Number(value),
            ]);
          }}
          value={voltageFilters?.[1]}
        />
      </StyledFlex>
    </Wrapper>
  );
};
