import { Fragment, PropsWithChildren, ReactElement } from "react";
import { Column, Row, useTable, useExpanded, TableOptions, usePagination } from "react-table";
import { AnySchema } from "yup";
import Lazy from "yup/lib/Lazy";
import Reference from "yup/lib/Reference";
import { LoadingState } from "../../constants/general";
import { EditableCell } from "./EditableCell/EditableCell";
import {
  TableStyleWrapper,
  TableStyle,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableDataCell,
  TableFooterStyled,
  TableRowHead,
  Pagination,
  PaginationButton,
  PaginationDataInfo,
  PaginationPagesInfo,
} from "./Table.styled";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as LeftArrow } from "../../assets/images/arrow_l.svg";
import { ReactComponent as Rightrrow } from "../../assets/images/arrow_r.svg";

interface TableProperties<T extends object> extends Omit<TableOptions<T>, "data"> {
  columns: Column<T>[];
  data?: T[] | null;
  updateField?: (row: Row<T>, column: Column, value: string) => Promise<LoadingState> | void;
  multiItem?: boolean;
  removeField?: (row: Row<T>) => void;
  validationParams?: ValidationParams;
  displayColumnFooter?: boolean;
  displayColumnFooterGroups?: boolean;
  displayColumnHeader?: boolean;
  stripes?: boolean;
  renderRowSubComponent?: (props: { row: Row<T> }) => React.ReactNode;
  paginate?: boolean;
  itemsPerPage?: number;
}

export type ValidationParams = Record<string, AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>;

const defaultColumn = {
  Cell: EditableCell,
  canRemove: false,
  editable: false,
};

export function TableBase<T extends object>({
  columns,
  data,
  validationParams,
  children,
  displayColumnFooter = true,
  displayColumnFooterGroups = true,
  displayColumnHeader = true,
  renderRowSubComponent,
  updateField,
  removeField,
  paginate,
  itemsPerPage,
  ...props
}: PropsWithChildren<TableProperties<T>>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    visibleColumns,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data || [],
      defaultColumn,
      updateField,
      removeField,
      validationParams,
      autoResetExpanded: false,
      initialState: { pageIndex: 0, pageSize: itemsPerPage ?? 7 },
    },
    useExpanded,
    usePagination
  );

  return (
    <>
      <TableStyle {...props} {...getTableProps()}>
        {displayColumnHeader ? (
          <TableHeader>
            {headerGroups.map((headerGroup) => (
              <TableRowHead {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeaderCell
                    {...column.getHeaderProps({
                      // @ts-ignore
                      className: column.className,
                    })}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </TableRowHead>
            ))}
          </TableHeader>
        ) : null}
        <TableBody {...getTableBodyProps()}>
          {(paginate ? page : rows).map((row, i) => {
            prepareRow(row);

            const { role, ...rowProps } = row.getRowProps();

            return (
              <Fragment {...rowProps}>
                <TableRow>
                  {row.cells.map((cell: any) => {
                    return (
                      <TableDataCell
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                          // @ts-ignore
                          className: cell.column.className,
                        })}
                      >
                        {cell.render("Cell")}
                      </TableDataCell>
                    );
                  })}
                </TableRow>
                {/* @ts-ignore */}
                {row.isExpanded ? (
                  <TableRow>
                    <TableDataCell colSpan={visibleColumns.length}>{renderRowSubComponent?.({ row })}</TableDataCell>
                  </TableRow>
                ) : null}
              </Fragment>
            );
          })}
        </TableBody>
        {displayColumnFooter && (
          <TableFooterStyled>
            {displayColumnFooterGroups
              ? footerGroups.map((footerGroup) => (
                  <TableRow noBorder {...footerGroup.getFooterGroupProps()}>
                    {footerGroup.headers.map((column) => (
                      <TableHeaderCell {...column.getFooterProps()}>{column.render("Footer")}</TableHeaderCell>
                    ))}
                  </TableRow>
                ))
              : null}
            <TableRow noBorder>
              <TableHeaderCell colSpan={visibleColumns.length}>{children}</TableHeaderCell>
            </TableRow>
          </TableFooterStyled>
        )}
      </TableStyle>

      {paginate && (
        <Pagination>
          <PaginationDataInfo>
            {Math.max(Math.min(pageSize * (pageIndex + 1), data?.length ?? 99999) - (pageSize - 1), 0)} -{" "}
            {Math.min(pageSize * (pageIndex + 1), data?.length ?? 99999)} of {data?.length} items
          </PaginationDataInfo>
          <StyledFlex ml="auto">
            <PaginationPagesInfo>
              <select value={pageIndex} onChange={(e) => gotoPage(Number(e.target.value))}>
                {[...Array(pageCount).keys()].map((idx) => (
                  <option key={idx} value={idx}>
                    {idx + 1}
                  </option>
                ))}
              </select>
              <span>of {pageCount} pages</span>
            </PaginationPagesInfo>
            <PaginationButton onClick={previousPage} disabled={!canPreviousPage}>
              <LeftArrow />
            </PaginationButton>
            <PaginationButton onClick={nextPage} disabled={!canNextPage}>
              <Rightrrow />
            </PaginationButton>
          </StyledFlex>
        </Pagination>
      )}
    </>
  );
}

export function Table<T extends object>({
  multiItem,
  stripes = false,
  ...tableProps
}: PropsWithChildren<TableProperties<T>>): ReactElement {
  return (
    <TableStyleWrapper multiItem={multiItem} stripes={stripes}>
      <TableBase {...tableProps} />
    </TableStyleWrapper>
  );
}

export default Table;
