export const capitalizeFirstLetter = (text: string) => {
  if (!text?.length) {
    return "";
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const capitalizeAndRemoveUnderscore = (text?: Nullable<string>) =>
  capitalizeFirstLetter((text || "").replaceAll("_", " "));

export const toUsdCurrency = (value: number | string, maximumFractionDigits = 0) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: maximumFractionDigits,
  }).format(Number(value));

export const formatNumber = (value: number, maximumFractionDigits = 2) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
};

export const formatTime = (val?: number) => {
  const totalMinutes = (val || 0) * 60;

  const hours = Math.floor(totalMinutes / 60);

  const minutes = Math.ceil(totalMinutes - hours * 60);

  if (!hours) {
    return `${minutes}min`;
  }

  if (!minutes) {
    return `${hours}h`;
  }

  return `${hours}h${minutes}min`;
};

export const splitBySpace = (text: string) => {
  if (text.includes(" ")) {
    return text.split(" ");
  }

  return text;
};
