import { useMutation, useQueryClient } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { dashboardSpansDataPairs } from "../constants";
import { DashboardNodeDto, ReportsResponseDto } from "../../reports/types";
import { StatusesType } from "../types";

export const useDashboardSpanData = ({ programId, status }: { programId?: string; status?: StatusesType }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ["dashboard-data", programId, status],
    (taxonomyNodeId: string) => {
      return ApiClient.post<ReportsResponseDto>("/reports/execute", {
        reportIds: [dashboardSpansDataPairs.find((item) => item.name === status)?.id],
        parameters: { programId, parentNodeId: taxonomyNodeId },
      }).then((res) => (res.data.results?.[0]?.data ?? []) as DashboardNodeDto[]);
    },
    {
      onSuccess: (data, variables) => {
        const prevData = queryClient.getQueryData(["dashboard-data", programId]) as ReportsResponseDto;
        const newResults = prevData.results.map((report) => {
          if (report.reportName === status) {
            return {
              ...report,
              data: (report.data as DashboardNodeDto[]).map((item) => {
                if (item.taxonomyNodeId === variables) {
                  return {
                    ...item,
                    subRows: data,
                  };
                }

                return item;
              }),
            };
          }

          return report;
        });
        queryClient.setQueryData(["dashboard-data", programId], { results: newResults });
      },
    }
  );
};
