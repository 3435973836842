import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
`;
