import { WorkTypeScope } from "./types/responses";

export const landingRoute = "/";

export const getForecastRoute = () => "/forecast";

export const getErrorRoute = () => "/general-error";

export const getAdminRoute = () => "/admin";

export const getAdminVoltageConfigurationRoute = () => `${getAdminRoute()}/voltage-configuration`;

export const getAdminElevationRoute = () => `${getAdminRoute()}/elevation`;

export const getAdminFacilitiesRoute = () => `${getAdminRoute()}/facilities`;

export const getAdminWorkTypeRoute = () => `${getAdminRoute()}/work-types`;

export const getAdminCrewConfigurationRoute = () => `${getAdminRoute()}/crew`;

export const getAdminCrewTypeConfigurationRoute = () => `${getAdminRoute()}/crew-type-config`;

export const adminRatesRoute = `${getAdminRoute()}/rates`;

export const plansRoute = `/plans`;
export const createPlanRoute = `${plansRoute}/new`;
export const editPlanRoute = (id: string) => `${plansRoute}/${id}`;

export const planningMode = "/planning-mode";
export const planningModeOverview = `${planningMode}/overview`;
export const planningModeCreate = `${planningMode}/create`;
export const planningModeEdit = `${planningMode}/:planId/edit`;

export const getStarCommandRoute = () => "/star-command";

export const workOrderRoute = "/work-order";

export const viewWorkOrderRoute = `${workOrderRoute}/:workOrderId/view`;

export const getViewWorkOrderRoute = (workOrderId: string) => `${workOrderRoute}/${workOrderId}/view`;

export const createWorkOrderRoute = `${workOrderRoute}/span/:spanId/scope/:workTypeScope/object/:objectId/create`;

export const getCreateWorkOrderRoute = (spanId: string, workTypeScope: WorkTypeScope, objectId: string) =>
  `${workOrderRoute}/span/${spanId}/scope/${workTypeScope}/object/${objectId}/create`;

export const createAssetWorkOrderRoute = `${workOrderRoute}/scope/:workTypeScope/object/:objectId/create`;

export const getAssetCreateWorkOrderRoute = (objectId: string) =>
  `${workOrderRoute}/scope/ASSET/object/${objectId}/create`;

export const createReworkWorkOrderRoute = `${workOrderRoute}/scope/:workTypeScope/work-order/:workOrderId/create`;

export const getReworkCreateWorkOrderRoute = (workOrderId: string) =>
  `${workOrderRoute}/scope/REWORK/work-order/${workOrderId}/create`;

export const getViewWorkOrderURL = (id: number | string) => `${workOrderRoute}/${id}/view`;
export const reportsRoute = "/reports";
