import { useEffect, useMemo, useState } from "react";
import { ReactComponent as SumIcon } from "../../../../assets/images/icon_sigma.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/images/refresh-arrows.svg";
import { ReactComponent as PriceIcon } from "../../../../assets/images/icon_dollar_sign.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendar.svg";
import { ReactComponent as ProgressIcon } from "../../../../assets/images/rotate_right.svg";
import { Option } from "../../../../components/Select";
import { ProgramsDropdown, RefreshWrapper, Wrapper } from "./Header.styled";
import { PlanDto } from "../../../planning/types";
import { getOptionsFromArray } from "../../../planning-mode/utils";
import { Modal } from "../../../planning-mode/components/Modal";
import { getYear } from "../../../planning-mode/components/Header/Header";
import IconWithText from "../../../planning-mode/components/Header/components/IconWithText";
import { NewProgramForm } from "../../../planning-mode/components/NewProgramForm";
import { PlansDropdown, PlansMenuList } from "../../../planning-mode/components/Header/components/PlansDropdown";
import { PlanInfoContainer, StatusBadge } from "../../../planning-mode/components/Header/Header.styled";
import { toUsdCurrency } from "../../../../utils/stringUtils";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { ProgramsForPlan } from "../../../planning-mode/types";
import { useCustomQuery } from "../../../../hooks";
import { WorkTypeResponse } from "../../../../types/responses";
import { getWorkTypesApiUrl } from "../../../../api";
import { roundValue } from "../../../../utils/unitHelper";

interface IProps {
  plans?: PlanDto[];
  programs?: ProgramsForPlan[];
  selectedPlan?: PlanDto;
  selectedProgram: ProgramsForPlan | undefined;
  onSelectPlan?: (plan: PlanDto | undefined) => void;
  onSelectProgram?: (program: ProgramsForPlan | undefined) => void;
  onCtaClick: () => void;
  areProgramsLoading: boolean;
  showRefreshArrow: boolean;
}

export const Header = ({
  plans,
  selectedPlan,
  selectedProgram,
  programs,
  areProgramsLoading,
  showRefreshArrow,
  onSelectPlan,
  onSelectProgram,
  onCtaClick,
}: IProps) => {
  const [showNewProgram, setShowNewProgram] = useState(false);
  const planOptions = useMemo(() => getOptionsFromArray(plans, "name", "id"), [plans]);
  const programOptions = useMemo(() => getOptionsFromArray(programs, "programName", "programId"), [programs]);
  const { data: workTypes = [] } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());

  const selectedOption = useMemo(
    () => planOptions.find((option) => option.value === selectedPlan?.id),
    [planOptions, selectedPlan]
  );

  useEffect(() => {
    if (selectedPlan) {
      const updatedPlan = plans?.find((plan) => plan.id === selectedPlan?.id);
      onSelectPlan?.(updatedPlan);
    } else {
      onSelectPlan?.(plans?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  return (
    <>
      <Wrapper justifyContent={"center"}>
        <StyledFlex maxWidth={"1400px"} flex={1} alignItems={"center"} minHeight={"45px"}>
          <PlansDropdown
            value={selectedOption}
            options={planOptions}
            onChange={(option: Option) => {
              const selectedPlan = plans?.find((plan) => plan.id === option.value);
              onSelectPlan?.(selectedPlan);
              onSelectProgram?.(undefined);
            }}
          />
          {selectedPlan ? (
            <PlanInfoContainer>
              <StatusBadge data-status={selectedPlan.status.toLocaleLowerCase()}>{selectedPlan.status}</StatusBadge>
              <IconWithText Icon={<CalendarIcon />}>
                {getYear(selectedPlan.start)} - {getYear(selectedPlan.end)}
              </IconWithText>
              <IconWithText Icon={<SumIcon />}>{selectedPlan.plannedCircuitsCount} circuits</IconWithText>
              <IconWithText Icon={<PriceIcon />}>{toUsdCurrency(selectedPlan.budget)}</IconWithText>
              <IconWithText Icon={<ProgressIcon width={18} height={18} />}>
                {roundValue(selectedPlan.progress, 0)}%
              </IconWithText>
            </PlanInfoContainer>
          ) : null}

          <StyledFlex ml={"auto"} alignItems={"center"} gap={"8px"}>
            {showRefreshArrow && (
              <RefreshWrapper>
                <RefreshIcon width={12} height={12} />
              </RefreshWrapper>
            )}
            <ProgramsDropdown
              width={"184px"}
              placeholder="Select Program"
              className={areProgramsLoading ? "isLoading" : ""}
              options={programOptions}
              isLoading={areProgramsLoading}
              value={programOptions.find((opt) => opt.value === selectedProgram?.programId)}
              components={{ MenuList: PlansMenuList }}
              selectProps={{ addNewText: "New Program", onAdd: () => setShowNewProgram(true) }}
              onChange={(option: Option) => {
                const selectedProgram = programs?.find((program) => program.programId === option.value);
                onSelectProgram?.(selectedProgram);
              }}
            />
          </StyledFlex>
        </StyledFlex>
      </Wrapper>
      {showNewProgram && (
        <Modal closeModal={() => setShowNewProgram(false)} title="New Program" isHidden={false} width="465px">
          <NewProgramForm
            closeModal={() => setShowNewProgram(false)}
            worktypeOptions={workTypes?.map((workType) => ({
              label: workType.name,
              value: workType.id,
            }))}
            selectedPlanId={selectedPlan?.id ?? ""}
          />
        </Modal>
      )}
    </>
  );
};
