import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";
import { getEnvValue } from "../get-env";

import { logError } from "../services";
import { useAuthStore } from "../stores/AuthStore";

const baseURL = getEnvValue("REACT_APP_BASE_URL");

class ApiClient {
  static refreshTokenPromise: Promise<void> | null = null;
  static client: AxiosInstance;
  static init() {
    const client = axios.create({
      baseURL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const handleError = (error: AxiosError) => {
      if (!error || !error.response) {
        return;
      }
      if (error.response?.status === 401 || error.response?.status === 403) {
        window.location.reload();
      }
      // log request error
      logError(`${error.response.status} - ${error}`);

      return Promise.reject(error);
    };

    client.interceptors.request.use((config) => {
      const token = useAuthStore.getState().getAccessToken();
      const traceId = uuidv4();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      if (traceId) {
        config.headers["traceId"] = traceId;
      }
      return config;
    }, handleError);

    client.interceptors.response.use((config) => config, handleError);

    ApiClient.client = client;
  }

  static get<T>(url: string, params?: unknown, data?: unknown, config?: AxiosRequestConfig) {
    return ApiClient.client.get<T>(url, { ...config, params, data: data });
  }

  static post<T>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return ApiClient.client.post<T>(url, data, config);
  }

  static put<T>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return ApiClient.client.put<T>(url, data, config);
  }

  static delete(url: string, config?: AxiosRequestConfig) {
    return ApiClient.client.delete(url, config);
  }

  static download(url: string) {
    return new Promise((resolve) => {
      window.open(`${ApiClient.client.defaults.baseURL}${url}`);

      return resolve(null);
    });
  }
}

export default ApiClient;
