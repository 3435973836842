import { useQuery } from "react-query";
import { vendorUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";
import { VendorResponse } from "../types";

const getVendors = () => ApiClient.get<VendorResponse>(vendorUrl).then((response) => response.data.vendors);

export const useVendors = () => {
  return useQuery("vendors", getVendors, {
    onError: logError,
  });
};

export default useVendors;
