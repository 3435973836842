import { useEffect } from "react";
import { useHistory } from "react-router";
import { getEnvValue } from "../../get-env";

import { getErrorRoute } from "../../routes";
import { logError } from "../../services";

export const EnvironmentVariablesCheck = () => {
  const history = useHistory();
  useEffect(() => {
    // Add check for new critical variables.
    if (
      !getEnvValue("REACT_APP_BASE_URL") ||
      !getEnvValue("REACT_APP_PUBLIC_ENV_NAME") ||
      !getEnvValue("REACT_APP_ACCESS_TOKEN_MAP") ||
      !getEnvValue("REACT_APP_KEYCLOAK_URL") ||
      !getEnvValue("REACT_APP_KEYCLOAK_REALM") ||
      !getEnvValue("REACT_APP_KEYCLOAK_CLIENT_ID") ||
      !getEnvValue("REACT_APP_MAPBOX_SATELLITE_URL") ||
      !getEnvValue("REACT_APP_MAPBOX_WHITE_MODE_URL") ||
      !getEnvValue("REACT_APP_MAPBOX_ROADS_URL") ||
      !getEnvValue("REACT_APP_MAPBOX_TOPOGRAPHIC_URL") ||
      !getEnvValue("REACT_APP_WEBSITE_NAME") ||
      !getEnvValue("REACT_APP_FAVICON_NAME")
    ) {
      // log error for future tracking tools
      logError("Required Environment variables are missing. Please check configuration files.");
      history.push(getErrorRoute());
    }
  }, [history]);

  return <></>;
};
