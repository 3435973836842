import React, { FC, AnchorHTMLAttributes } from "react";
import { Button, IconImg } from "./IconButton.styled";
import { SpaceProps, LayoutProps, BorderProps, PaddingProps, MarginProps } from "styled-system";
import { Spinner } from "../Loader/Spinner";
import { StyledFlex } from "../../assets/styles/flex.styled";

interface Props
  extends SpaceProps,
    LayoutProps,
    BorderProps,
    PaddingProps,
    MarginProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  iconUrl?: Nullable<string>;
  Icon?: Nullable<FC<React.SVGProps<SVGSVGElement>>>;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  as?: React.ElementType;
  text?: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const IconButton: FC<Props> = ({
  iconUrl,
  Icon,
  text,
  onClick,
  className,
  as = "button",
  isLoading = false,
  disabled,
  ...props
}) => {
  if (isLoading) {
    return (
      <Button as={as} onClick={onClick} className={className} {...props} disabled={disabled}>
        <Spinner color={`var(--colors-eastern-blue)`} width="10px" height="10px" m={0} />
        {text}
      </Button>
    );
  }

  if (!!iconUrl) {
    return (
      <Button as={as} onClick={onClick} className={className} {...props} disabled={disabled}>
        <StyledFlex justifyContent="center" alignItems="center">
          <IconImg src={iconUrl} alt={text} />
        </StyledFlex>
        {text}
      </Button>
    );
  }

  return (
    <Button as={as} onClick={onClick} className={className} {...props} disabled={disabled}>
      {!!Icon ? <Icon /> : <></>}
      {text}
    </Button>
  );
};
