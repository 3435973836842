import L from "leaflet";
import { useEffect, useState } from "react";

export const useMapOptimizedHiddenFlag = (hidden: boolean) => {
  const [isHidden, setIsHidden] = useState(hidden);

  useEffect(() => {
    L.Util.requestAnimFrame(() => {
      setIsHidden(hidden);
    });
  }, [hidden]);

  return [isHidden];
};
