import styled from "styled-components";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { RIGHT_SIDEBAR_WIDTH } from "../../constants/dimensions";

export const PlanningModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  &.right-portal-visible {
    max-width: calc(100% - ${RIGHT_SIDEBAR_WIDTH}px);
  }
`;

export const PlanningModeMapContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const SpinnerWrapper = styled(StyledFlex)`
  height: 400px;
  justify-content: center;
  align-items: center;
`;
