import { HOVERED_OBJECT_CLASS_PREFIX } from "../../../pages/MapScreen/MapView/MapView.constants";
import { RISK_PRIORITY } from "../../../types/responses";
import MapHelper from "../../../utils/mapHelper";
import { isGeoJSONEmpty } from "../../../utils/mapUtils";
import {
  FEEDER_STYLE_SELECTED,
  FEEDER_STYLE,
  FEEDER_PRIORITY_VIOLATION,
  FEEDER_PRIORITY_CRITICAL,
  FEEDER_PRIORITY_HIGH,
  FEEDER_PRIORITY_MEDIUM,
  FEEDER_PRIORITY_LOW,
} from "../constants";
import { FeedersResponse, MappedFeedersGeoJSON } from "../types/FeedersResponse";

export const getFeederStyle = (
  item: GeoJSON.Feature<GeoJSON.Geometry, { id: string; riskPriority: RISK_PRIORITY }>,
  selectedFeeder: Nullable<string>
) => {
  if (selectedFeeder && item.properties.id === selectedFeeder) {
    return { className: `${HOVERED_OBJECT_CLASS_PREFIX}${item.properties.id} map-feeder`, ...FEEDER_STYLE_SELECTED };
  }

  const priorityColor =
    item.properties.riskPriority === "VIOLATION"
      ? FEEDER_PRIORITY_VIOLATION
      : item.properties.riskPriority === "CRITICAL"
      ? FEEDER_PRIORITY_CRITICAL
      : item.properties.riskPriority === "HIGH"
      ? FEEDER_PRIORITY_HIGH
      : item.properties.riskPriority === "MEDIUM"
      ? FEEDER_PRIORITY_MEDIUM
      : FEEDER_PRIORITY_LOW;

  return {
    className: `${HOVERED_OBJECT_CLASS_PREFIX}${item.properties.id} map-feeder`,
    ...FEEDER_STYLE,
    ...priorityColor,
  };
};

export type MappedFeedersResponse = ReturnType<typeof mapFeedersResponse>;

export const mapFeedersResponse = (feeders: FeedersResponse) => {
  if (isGeoJSONEmpty(feeders)) {
    return;
  }
  const center = MapHelper.centroid(false, feeders);
  const bounds = MapHelper.normalizeBounds(MapHelper.bBox(false, feeders));

  const map: MappedFeedersGeoJSON = {};
  feeders.features.forEach((feeder) => {
    const feederCenter = MapHelper.centroid(false, feeder);
    const feederBox = MapHelper.bBox(true, [feeder]);

    map[feeder.properties.id] = {
      center: [feederCenter.geometry.coordinates[1], feederCenter.geometry.coordinates[0]],
      properties: feeder.properties,
      feederBox: MapHelper.normalizeBounds(feederBox) as L.LatLngBoundsExpression,
    };
  });

  return {
    center: [center.geometry.coordinates[1], center.geometry.coordinates[0]] as L.LatLngExpression,
    feedersData: map,
    feedersGeoJson: feeders,
    bounds: bounds,
  };
};
