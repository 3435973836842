import { useQuery } from "react-query";
import { getWorkOrderPreviewUrl } from "../../../api";
import ApiClient from "../../../utils/apiClient";
import { usePlanningStore } from "../stores";
import { WorkOrderPreviewResponse } from "../types";

export const WORK_ORDER_QUERY_KEY = "work-order-preview";

const useWorkOrderPreview = (planId?: string, programId?: string) => {
  const setWorkOrderForProgramPreview = usePlanningStore((state) => state.setWorkOrderForProgramPreview);
  const programs = usePlanningStore((store) => store.programsForPlan?.[planId || ""]);

  const program = programs?.find((p) => p.programId === programId);

  const nodeIds = (program?.nodes || []).map((node) => node.taxonomyNodeId);

  return useQuery(
    [WORK_ORDER_QUERY_KEY, programId],
    () => ApiClient.get<WorkOrderPreviewResponse>(getWorkOrderPreviewUrl(programId ?? "", nodeIds)),
    {
      onSuccess: (response) => {
        if (!programId || !planId) {
          return;
        }

        const circuits = (response.data.circuits ?? []).map((circuit) => ({
          ...circuit,
          spans: circuit.spans.filter((span) => span.suggestedWork.length > 0),
        }));

        setWorkOrderForProgramPreview(planId, programId, circuits);
      },
      enabled: Boolean(programId && nodeIds?.length),
      cacheTime: 0,
    }
  );
};

export default useWorkOrderPreview;
