import styled from "styled-components";
import { color, ColorProps } from "styled-system";

import { RISK_PRIORITY } from "../../../../types/responses";

export const StatusCircle = styled.div<ColorProps>`
  ${color}
  box-sizing: border-box;
  min-height: 10px;
  min-width: 10px;
  border-radius: 4.5px;
  margin-right: 3px;
`;

export const FeederCircleStatus = styled.div<ColorProps>`
  ${color}
  box-sizing: border-box;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;

  &[data-priority] {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  &[data-priority=${RISK_PRIORITY.LOW}] {
    background-color: var(--colors-priority-low);
  }

  &[data-priority=${RISK_PRIORITY.MEDIUM}] {
    background-color: var(--colors-priority-medium);
  }

  &[data-priority=${RISK_PRIORITY.HIGH}] {
    background-color: var(--colors-priority-high);
  }

  &[data-priority=${RISK_PRIORITY.CRITICAL}] {
    background-color: var(--colors-priority-critical);
  }

  &[data-priority=${RISK_PRIORITY.VIOLATION}] {
    width: 15px;
    height: 15px;
    margin-right: 0;
  }
`;

export const StatusViolationTriangle = styled.img`
  height: 15px;
  width: 15px;
`;

export const ShowAllImage = styled.img``;
