import { Point } from "geojson";
import create from "zustand";

import { RISK_PRIORITY } from "../../../types/responses";
import MapHelper from "../../../utils/mapHelper";
import { RiskMarkersPosition, TaxonomyNodeDto } from "../types";

export type TaxonomyStoreState = {
  data: Nullable<TaxonomyNodeDto[]>;
  currentRegion: Nullable<string>;
  rootRegion: Nullable<string>;
  nodes: Nullable<Record<string, TaxonomyNodeDto[]>>;
  boundsByRegion: Nullable<Record<string, L.LatLngBoundsExpression>>;
  defaultCenter?: Nullable<L.LatLngExpression>;
  isFeederLevel: boolean;
  riskPrioritySelected: Nullable<RISK_PRIORITY>;
  regionsPriority: Nullable<Record<string, RISK_PRIORITY>>;
  regionsByRiskPriority: Nullable<{
    positions: Nullable<RiskMarkersPosition[]>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }>;
  actions: {
    setData: (data: Nullable<{ nodes: TaxonomyNodeDto[] }>, regionId: string) => void;
    setCurrentRegion: (regionId: Nullable<string>) => void;
    setRootRegion: (rootRegionId: Nullable<string>) => void;
    getFeederId: (objectId: string) => Nullable<string>;
    setRiskPriority: (riskPriority: Nullable<RISK_PRIORITY>) => void;
    setSpanRiskPriority: (riskPriority: RISK_PRIORITY, feederId: Nullable<string>) => void;
    clickOnSpanFromCutPlan: (centerPoint: Point, id: string) => void;
    findByObjectId: (objectId: string) => Nullable<string>;
    setIsFeederLevel: (isFeederLevel: boolean) => void;
  };
};

export const useTaxonomyStore = create<TaxonomyStoreState>((set, get) => ({
  data: null,
  currentRegion: null,
  nodes: {},
  boundsByRegion: {},
  rootRegion: null,
  defaultCenter: null,
  isFeederLevel: false,
  riskPrioritySelected: null,
  regionsByRiskPriority: null,
  regionsPriority: {},
  actions: {
    setData: (data, regionId) => {
      if (!data?.nodes?.length) {
        return;
      }
      const regions = { ...get().nodes };
      const boundsByRegion = { ...get().boundsByRegion };
      const regionsPriority = { ...get().regionsPriority };

      regions[regionId] = data.nodes;

      const bounds = calculateBounds(data.nodes);
      if (bounds && !boundsByRegion?.[regionId]) boundsByRegion[regionId] = bounds;

      data.nodes.forEach((region) => {
        region.properties.riskPriority = region.properties.riskPriority ?? RISK_PRIORITY.LOW;

        region.properties.riskScore = region.properties.riskScore ?? 0;
        const nodeId = region.properties.objectType === "span" ? region.properties.objectId : region.properties.id;
        regionsPriority[nodeId] = region?.properties?.riskPriority ?? RISK_PRIORITY.LOW;
        if (region.geometry !== null) {
          const bounds = MapHelper.normalizeBounds(MapHelper.bBox(false, region.geometry)) as L.LatLngBoundsExpression;
          if (bounds && !boundsByRegion?.[nodeId]) boundsByRegion[nodeId] = bounds;
        }
      });

      // set defaultCenter only on init
      if (get().rootRegion === regionId) {
        const center = MapHelper.centroid(true, data.nodes ?? []);
        center && set({ defaultCenter: [center.geometry.coordinates[1], center.geometry.coordinates[0]] });
      }

      set({ nodes: regions, boundsByRegion, regionsPriority });
    },
    setCurrentRegion: (regionId) => {
      if (get().currentRegion === regionId) {
        return;
      }

      set({
        currentRegion: regionId ?? get().rootRegion,
        isFeederLevel: !!regionId && get().rootRegion !== regionId,
      });
    },
    setRootRegion: (rootRegionId) => set({ rootRegion: rootRegionId, currentRegion: rootRegionId }),
    getFeederId: (feederId) => {
      const currentRegion = get().currentRegion ?? get().rootRegion;
      const nodes = { ...get().nodes };
      if (!currentRegion || !nodes?.[currentRegion]) {
        return null;
      }
      return nodes?.[currentRegion]?.find((item) => item.properties.objectId === feederId)?.properties.id ?? null;
    },
    setRiskPriority: (riskPrioritySelected) => set({ riskPrioritySelected }),
    setSpanRiskPriority: (riskPriority, feederId) => {
      if (riskPriority === get().riskPrioritySelected) {
        return;
      }

      const regions = get().nodes;

      if (!feederId || !riskPriority) {
        set({ regionsByRiskPriority: null, riskPrioritySelected: null });
        return;
      }

      if (!regions?.[feederId]) {
        return set({ riskPrioritySelected: riskPriority });
      }

      const filteredSpans = regions?.[feederId]?.filter(
        (region) =>
          region.properties.objectType === "span" &&
          region.geometry !== null &&
          region.properties.riskPriority === riskPriority
      );
      const spanPositions = filteredSpans?.map((region) => {
        return {
          ...region.properties,
          position: MapHelper.centroid(false, region.geometry)?.geometry?.coordinates?.reverse(),
        };
      });

      set({
        regionsByRiskPriority: {
          bounds: calculateBounds(filteredSpans ?? []),
          positions: spanPositions ?? [],
        },
        riskPrioritySelected: riskPriority,
      });
    },
    clickOnSpanFromCutPlan: (centerPoint, spanId) => {
      if (!centerPoint?.coordinates?.length || centerPoint.coordinates.length < 2 || !spanId) {
        return;
      }

      const boundsByRegion = { ...get().boundsByRegion };
      const bounds = MapHelper.getBoundsFromPoint([centerPoint.coordinates[0], centerPoint.coordinates[1]]);
      if (!bounds) {
        return;
      }
      boundsByRegion[spanId] = bounds;
      set({ boundsByRegion });
    },
    findByObjectId: (objectId) => {
      if (!objectId) {
        return null;
      }
      const regions = get().nodes ?? {};

      return (
        Object.keys(regions)
          .map((key) => regions[key])
          .reduce(function (pre, cur) {
            return pre.concat(cur);
          }, [])
          ?.find((item) => item.properties.objectId === objectId)?.properties?.id ?? null
      );
    },
    setIsFeederLevel: (isFeederLevel) => set({ isFeederLevel }),
  },
}));

const calculateBounds = (nodes: TaxonomyNodeDto[]) => {
  return MapHelper.normalizeBounds(
    MapHelper.bBox(false, {
      type: "GeometryCollection",
      geometries: nodes?.map((item) => item.geometry),
    })
  ) as L.LatLngBoundsExpression;
};

export default useTaxonomyStore;
