import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { Select } from "../../../../components/Select";

export const Wrapper = styled(StyledFlex)`
  padding: 16px 32px;
  background-color: var(--colors-white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const ProgramsDropdown = styled(Select)`
  &,
  .react-select__control {
    height: 36px;
  }

  &.isLoading .react-select__placeholder {
    display: none;
  }

  .react-select__menu {
    z-index: 3;
  }
`;

export const RefreshWrapper = styled.div`
  svg {
    animation-name: ckw;
    animation-duration: 2s;
    /* Things added */
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    display: inline-block;
  }

  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
