import { useQuery } from "react-query";

import { getStructuresApiUrlByExtent } from "../../../api";

import useStructuresStore from "../stores/StructuresStore";
import { StructuresResponse } from "../types/StructuresResponse";
import ApiClient from "../../../utils/apiClient";
import { useExtentParams } from "../../../hooks/useExtentParams";
import { MIN_ZOOM_LEVEL_FOR_STRUCTURES } from "../constants";

const QUERY_KEY = "structures";

export const useStructures = () => {
  const params = useExtentParams();
  const setStructuresData = useStructuresStore((store) => store.setStructuresData);

  const isLayerVisible = params !== null && params.zoomLevel > MIN_ZOOM_LEVEL_FOR_STRUCTURES;

  return useQuery(
    [QUERY_KEY, params, isLayerVisible],
    () =>
      ApiClient.get(params !== null ? getStructuresApiUrlByExtent(params) : `/${QUERY_KEY}`).then(
        (res) => res.data as Nullable<StructuresResponse>
      ),
    {
      onSuccess: setStructuresData,
      enabled: isLayerVisible && params !== null,
      cacheTime: 0,
    }
  );
};
