import create from "zustand";
import { EMPTY_FN } from "../../../constants";

import { TreeCanopy, TreeCanopyResponse, TREE_CANOPY_FILTER } from "../types/TreeCanopy";

export type TreeCanopyById = Record<string, TreeCanopy>;

export type TreeCanopyFilter = Record<TREE_CANOPY_FILTER, boolean>;

export const INITIAL_OPACITY = 0.8;

export type TreeCanopyStore = {
  byId: Nullable<TreeCanopyById>;
  geoJSON: Nullable<TreeCanopyResponse>;
  treeCanopyFilter: TreeCanopyFilter;
  dangerTreeEnabled: boolean;
  setData: (treeCanopyData: Nullable<TreeCanopyResponse>) => void;
  selectCanopyId: (id: Nullable<string>) => void;
  selectedCanopyId: Nullable<string>;
  layerVisible: boolean;
  toggleLayer: () => void;
  setLayerState: (newState: boolean) => void;
  initialOpacity: number;
  setOpacity: (opacity: number) => void;
  dangerTreeOpacity: number;
  setDangerTreeOpacity: (opacity: number) => void;
  toggleFilter: (type: TREE_CANOPY_FILTER) => void;
  toggleDangerTree: () => void;
};
const INITIAL_STATE = {
  byId: null,
  geoJSON: null,
  selectedCanopyId: null,
  layerVisible: false,
  dangerTreeEnabled: false,
  initialOpacity: INITIAL_OPACITY,
  dangerTreeOpacity: INITIAL_OPACITY,
  treeCanopyFilter: { FALL_IN_RISK: true, DYING: true, HAZARD: true },
  toggleFilter: EMPTY_FN,
};

export const useTreeCanopyStore = create<TreeCanopyStore>((set, get) => ({
  ...INITIAL_STATE,
  setData: (treeCanopyData: Nullable<TreeCanopyResponse>) => {
    if (treeCanopyData?.features?.length) {
      set(mapTreeCanopyResponse(treeCanopyData));
    }
  },
  selectCanopyId: (id) => {
    if (id === get().selectedCanopyId) {
      return;
    }

    set({ selectedCanopyId: id });
  },
  toggleLayer: () => {
    const newVisibility = !get().layerVisible;
    //disable dangerTrees if treeCanopy is enabled
    set({
      layerVisible: newVisibility,
      initialOpacity: newVisibility ? get().initialOpacity : INITIAL_OPACITY,
      dangerTreeEnabled: !newVisibility ? get().dangerTreeEnabled : false,
    });
  },
  setLayerState: (state) => {
    if (get().layerVisible === state) {
      return;
    }

    set({ layerVisible: state, initialOpacity: state ? get().initialOpacity : INITIAL_OPACITY });
  },
  setOpacity: (opacity) => {
    if (get().initialOpacity === opacity) {
      return;
    }
    set({ initialOpacity: opacity });
  },
  setDangerTreeOpacity: (opacity) => {
    if (get().dangerTreeOpacity === opacity) {
      return;
    }
    set({ dangerTreeOpacity: opacity });
  },
  toggleFilter: (type) => {
    const treeCanopyFilter = { ...get().treeCanopyFilter };
    treeCanopyFilter[type] = !treeCanopyFilter[type];
    set({ treeCanopyFilter });
  },
  toggleDangerTree: () => {
    const nextState = !get().dangerTreeEnabled;
    //disable treeCanopy if dangerTrees is enabled
    set({ dangerTreeEnabled: nextState, layerVisible: !nextState ? get().layerVisible : false });
  },
}));

export const mapTreeCanopyResponse = (geoJSON: TreeCanopyResponse) => {
  const byId: TreeCanopyById = geoJSON.features.reduce((prev, curr) => {
    prev[curr.properties.id] = curr;

    return prev;
  }, {} as TreeCanopyById);

  return {
    byId,
    geoJSON,
  };
};
