import styled from "styled-components";
import { IconButton } from "../IconButton";

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #e7f7f9;
  border-radius: 30px;
  padding: 7px 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  max-height: 30px;
  line-height: 1;

  span {
    white-space: nowrap;
    margin-right: 5px;
  }

  span.value {
    margin-right: 20px;
  }

  svg {
    margin-right: 7px;
  }
`;

export const CloseButton = styled(IconButton)`
  background-color: transparent;
  border: none;
  padding: 0;

  svg {
    width: 8px;
    aspect-ratio: 1;
    fill: var(--colors-vantage-green);
  }
`;
