export function getEnv() {
  if (process.env.REACT_APP_USE_RUNTIME_ENV) {
    return window.__env__;
  }

  return process.env;
}

export function getEnvValue(key: string) {
  const env = getEnv();

  return env?.[key];
}
