import styled from "styled-components";

export const Wrapper = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 5px;
  width: 95px;
  height: 64px;
  background: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 1px solid transparent;

  .label {
    font-weight: 400;
    font-size: 10px;
    color: var(--colors-slate-500);
    text-transform: uppercase;
    white-space: nowrap;
  }

  .value {
    font-weight: 600;
    font-size: 20px;
    color: var(--colors-outer-space);
    line-height: 1;
  }

  &.isSelected:not(.isLoading) {
    border: 1px solid var(--colors-teal-3);

    .label,
    .value {
      color: var(--colors-teal-3);
    }
  }

  &:hover:not(.isLoading) {
    cursor: pointer;
    border: 1px solid var(--colors-teal-3);
  }

  &.isLoading {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;
