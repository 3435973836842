import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { components, GroupTypeBase, IndicatorProps, OptionTypeBase, MenuListComponentProps } from "react-select";
import { Select, SelectProps } from "../../../../../../components/Select";
import { RIGHT_DRAWER } from "../../../../../../constants";
import { ReactComponent as ChevronDown } from "../../../../../../assets/images/icon_chevron_down.svg";
import { ReactComponent as PlusRounded } from "../../../../../../assets/images/icon_plus_circle.svg";
import { planningModeOverview } from "../../../../../../routes";

const StyledSelect = styled(Select)`
  width: 240px;

  .react-select {
    &__control {
      border: none;

      &:hover {
        cursor: pointer;
        border: none;
        box-shadow: none;
        background-color: inherit;
      }

      &:focus {
        background-color: inherit;
      }

      &--is-focused {
        border: none;
        box-shadow: none;
      }

      &--menu-is-open {
        .react-select__indicator {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }
    }

    &__value-container {
      padding: 0;
    }

    &__single-value {
      font-size: 20px;
      font-weight: 600;
      display: block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__indicator {
      color: var(--colors-slate-600);

      &:hover {
        color: var(--colors-slate-900);
      }

      svg {
        transition: transform 0.3s ease-in-out;
      }
    }

    &__menu {
      border: 1px solid;
      z-index: ${RIGHT_DRAWER + 2};
      overflow-x: hidden;
      margin-top: 9px;
    }

    &__menu-list {
      overflow-x: hidden;
      padding: 0;
    }

    &__option {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const StyledOption = styled.button`
  display: flex;
  align-items: center;
  color: var(--colors-slate-500);
  font-weight: 600;
  text-decoration: none;
  border: none;
  background: none;
  width: 100%;
  border-bottom: 1px solid var(--colors-slate-200);
  padding: 8px 12px;

  svg {
    margin-right: 8px;
  }
`;

const DropdownIndicator = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: IndicatorProps<OptionType, IsMulti, GroupType>
) => (
  <components.DropdownIndicator {...props}>
    <ChevronDown />
  </components.DropdownIndicator>
);

export const PlansMenuList = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  children,
  ...props
}: MenuListComponentProps<OptionType, IsMulti, GroupType>) => {
  const { addNewText, onAdd } = props.selectProps.selectProps;

  return (
    <components.MenuList {...props}>
      <StyledOption onClick={onAdd} className="react-select__option react-select__option__new-plan">
        <PlusRounded /> {addNewText}
      </StyledOption>
      {children}
    </components.MenuList>
  );
};

export const PlansDropdown = ({ ...props }: SelectProps) => {
  const history = useHistory();

  return (
    <StyledSelect
      ariaLabel="Select plan"
      placeholder="Select plan"
      selectProps={{ addNewText: "New Plan", onAdd: () => history.push(planningModeOverview) }}
      components={{ DropdownIndicator, MenuList: PlansMenuList }}
      {...props}
    />
  );
};
