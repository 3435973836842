import styled from "styled-components";
import { ERROR_MESSAGE } from "../../../constants";

import { ReactComponent as WarningIcon } from "./../../../assets/images/warning_icon.svg";
interface CellProperties {
  flex: boolean;
}

export const EditableCellInput = styled.input<{
  editable?: boolean;
  error?: boolean;
  canRemove?: boolean;
  isDirty?: boolean;
}>`
  width: ${(props) => (!props.canRemove ? "96%" : "98%")};
  height: 30px;
  appearance: none;
  outline: none;
  border-radius: 3px;
  border: none;
  background: inherit;
  padding-left: 11px;
  &:hover {
    border: ${(props) => (props.editable ? `1px solid ${props.theme.colors.powderBlue}` : "none")};
  }
  &:focus {
    background: ${(props) => (props.editable ? props.theme.colors.alabaster2 : props.theme.colors.transparent)};
    border: ${(props) =>
      props.editable
        ? `1px solid  ${!props.error ? props.theme.colors.powderBlue : props.theme.colors.burntSienna}`
        : "none"};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => {
    if (props.error) {
      return props.theme.colors.burntSienna;
    }
    if (props.isDirty) {
      return props.theme.colors.teal3;
    }
    return props.theme.colors.trout2;
  }};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveImg = styled.img<{ activeInput?: boolean }>`
  width: 12px;
  height: 13px;
  cursor: pointer;
  display: ${(props) => (props.activeInput ? "block" : "none")};
`;

export const CellWrapper = styled.div<CellProperties>`
  width: 100%;
  border: none;
  height: 30px;
  display: ${(props) => (props.flex ? "flex" : "block")};
  align-items: center;
  &:hover > div > img {
    display: block;
  }
`;

export const ImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  border-radius: 5px;
`;

export const EditableCellWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ErrorIcon = styled(WarningIcon)`
  position: absolute;
  top: calc(50% - 4px);
  width: 10px;
  height: 10px;
  right: 10px;
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.burntSienna};
  display: block;
  text-align: start;
  position: absolute;
  width: calc(100% + 2px);
  height: fit-content;
  padding: 3px;
  background: ${(props) => props.theme.colors.fairPink};
  border: 1px solid ${(props) => props.theme.colors.tuftBush};
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  z-index: ${ERROR_MESSAGE};
  margin-top: 6px;
  text-align: center;
`;
