import { FORECAST_QUERY_PARAMS } from "../constants/navigation";

export const getSearchParams = () => new URLSearchParams(window.location.search);

export const resetToLeftMenuSearchParams = (params: URLSearchParams) => {
  if (!params) {
    return params;
  }

  deleteEncroachmentIdParam({ params });
  deleteStructureIdParam({ params });
  deleteParcelIdParam({ params });

  return params;
};

export const resetToRegionLevelSearchParams = (params: URLSearchParams) => {
  if (!params) {
    return params;
  }
  resetToLeftMenuSearchParams(params);
  deleteFeederIdParam({ params });
  deleteSpanIdParam({ params });

  return params;
};

export const resetToFeederLevelSearchParams = (params: URLSearchParams) => {
  if (!params) {
    return params;
  }

  deleteEncroachmentIdParam({ params });
  deleteSpanIdParam({ params });
  deleteStructureIdParam({ params });
  deleteParcelIdParam({ params });

  return params;
};

export const setFeederIdParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.FEEDER_ID, id);

  return queryParams;
};

export const deleteFeederIdParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.FEEDER_ID);

  return queryParams;
};

export const setSpanRiskPriorityParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.SPAN_RISK_PRIORITY, id);

  return queryParams;
};
export const deleteSpanRiskPriorityParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.SPAN_RISK_PRIORITY);

  return queryParams;
};

export const setSpanIdParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.SPAN_ID, id);

  return queryParams;
};

export const deleteSpanIdParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.SPAN_ID);

  return queryParams;
};

export const setRegionIdParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.REGION_ID, id);

  return queryParams;
};
export const deleteRegionIdParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.REGION_ID);

  return queryParams;
};

export const setEncroachmentIdParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.ENCROACHMENT_ID, id);

  return queryParams;
};
export const deleteEncroachmentIdParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.ENCROACHMENT_ID);

  return queryParams;
};

export const setParcelIdParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.PARCEL_ID, id);

  return queryParams;
};
export const deleteParcelIdParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.PARCEL_ID);

  return queryParams;
};

export const setStructureIdParam = ({ id, params }: { id: string; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.set(FORECAST_QUERY_PARAMS.STRUCTURE_ID, id);

  return queryParams;
};
export const deleteStructureIdParam = ({ params }: { params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  queryParams.delete(FORECAST_QUERY_PARAMS.STRUCTURE_ID);

  return queryParams;
};

export const setVisibleDrawerParam = ({ params, visible }: { visible: boolean; params?: URLSearchParams }) => {
  const queryParams = params || getSearchParams();
  if (visible) {
    queryParams.set(FORECAST_QUERY_PARAMS.VISIBLE_DRAWER, String(visible));
  } else {
    queryParams.delete(FORECAST_QUERY_PARAMS.VISIBLE_DRAWER);
  }

  return queryParams;
};

export const getQueryParamValue = (key: string) => getSearchParams().get(key);

export const hasQueryParam = (key: string) => getSearchParams().has(key);
