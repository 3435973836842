import { useMutation } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { getBulkAssign } from "../../../api";
import { logError } from "../../../services";

interface NodeItem {
  taxonomyNodeId: string;
  vendorId?: string;
  crewId?: string;
}
export const useBulkAssign = () => {
  return useMutation(
    ({ programId, nodes }: { programId: string; nodes: NodeItem[] }) =>
      ApiClient.post(getBulkAssign(programId), { nodes }),
    {
      onError: logError,
    }
  );
};
