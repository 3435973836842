import { useMemo } from "react";
import { useVendors } from "../../admin-panel/hooks";
import { getOptionsFromArray } from "../utils";

export const useVendorsOptions = (filterIDs?: string[]) => {
  const { data } = useVendors();

  const options = useMemo(() => {
    if (!data) return [];

    const filteredData =
      filterIDs !== undefined && filterIDs.length > 0
        ? data.filter((item) => filterIDs.some((fID) => fID === item.id))
        : data;
    return getOptionsFromArray(filteredData, "name", "id");
  }, [data, filterIDs]);

  return options;
};
