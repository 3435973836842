import React, { FC, Ref } from "react";
import { LayoutProps, SpaceProps } from "styled-system";
import {
  components,
  IndicatorProps,
  Props,
  OptionProps,
  GroupTypeBase,
  OptionTypeBase,
  ValueContainerProps,
} from "react-select";
import { useField } from "formik";

import { StyledSelect, StyledControlledSelect } from "./Select.styled";
import { ReactComponent as SelectOpenIcon } from "./../../assets/images/select_open_icon.svg";
import { WorktypeSource } from "../../types/responses";
import { Checkbox } from "../Checkbox";
import { InputErrorMessage, Label } from "../Input/Input.styled";
import { WorkTypeItem } from "../WorkTypeItem";

export interface Option {
  label: string;
  value: string;
}

type SelectDefaultProps = Omit<Props, "onChange">;

export interface SelectProps<TOption = Option> extends SelectDefaultProps, LayoutProps, SpaceProps {
  options: TOption[];
  isDisabled?: boolean;
  value?: Nullable<Option>;
  onChange?: (option: TOption) => void;
  name?: string;
  source?: WorktypeSource;
  innerRef?: Ref<unknown>;
  id?: string;
  label?: string;
}

const DropdownIndicator = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: IndicatorProps<OptionType, IsMulti, GroupType>
) => (
  <components.DropdownIndicator {...props}>
    <SelectOpenIcon />
  </components.DropdownIndicator>
);

export const CheckboxOption = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: OptionProps<OptionType, IsMulti, GroupType>
) => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox
          id={props.data.id}
          label={props.label}
          value={props.data.value}
          name={props.data.value}
          checked={props.isSelected}
          onClick={() => null}
        />
      </components.Option>
    </div>
  );
};

export const WorktypeOption = <
  OptionType extends OptionTypeBase,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: OptionProps<OptionType, IsMulti, GroupType>
) => {
  return (
    <div>
      <components.Option {...props}>
        <WorkTypeItem
          id={props.data.workTypeId}
          key={props.data.workTypeId}
          cost={props.data.cost}
          name={props.label ?? ""}
          code={props.data.code}
          iconUrl={props.data.iconUrl}
        />
      </components.Option>
    </div>
  );
};

export const MultiValueContainer = <
  OptionType extends OptionTypeBase,
  IsMultiple extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  children,
  ...props
}: ValueContainerProps<OptionType, IsMultiple, GroupType>) => {
  let [values, input] = children as any;
  const multiValueText = props.selectProps.selectProps?.multiValueText ?? "Selected: ";

  if (Array.isArray(values)) {
    values = `${multiValueText} ${values.length}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export const FilterMultipleValueContainer = <
  OptionType extends OptionTypeBase,
  IsMultiple extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  children,
  ...props
}: ValueContainerProps<OptionType, IsMultiple, GroupType>) => {
  const childrenAsArray = React.Children.toArray(children);
  const visibleChildren = childrenAsArray.slice(0, 3);

  const nonVisibleCount = childrenAsArray.length - visibleChildren.length - 1;

  return (
    <>
      <components.ValueContainer {...props}>
        {React.Children.map(visibleChildren, (child) => child)}
        {nonVisibleCount > 0 ? <>+ {nonVisibleCount} more</> : null}
      </components.ValueContainer>
    </>
  );
};

export const Select = <TOption extends Option>({
  options,
  value,
  onChange,
  innerRef,
  isDisabled = false,
  components = {},
  ...rest
}: React.PropsWithChildren<SelectProps<TOption>>): React.ReactElement | null => (
  <>
    <StyledSelect
      className="vantage-react-select-container"
      classNamePrefix="react-select"
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
      options={options}
      value={value}
      components={{ DropdownIndicator, ...components }}
      {...(innerRef && { ref: innerRef })}
      {...rest}
    />
  </>
);

export const ControlledSelect: FC<SelectProps> = ({ label, id, onChange, isMulti, ...props }) => {
  const [{ name }, meta, helpers] = useField(props.name || "default");
  const hasError = meta.error && meta.touched ? true : false;
  const { setValue, setTouched } = helpers;

  return (
    <StyledControlledSelect className={hasError ? "hasError" : ""}>
      {label && <Label htmlFor={id || name}>{label}</Label>}
      <Select
        isMulti={isMulti}
        {...props}
        onChange={(option) => {
          if (isMulti) {
            setValue(option);
            onChange?.(option);
          } else {
            setValue(option?.value ?? null);
          }
        }}
        onBlur={setTouched}
      />
      {hasError ? <InputErrorMessage>{meta.error || ""}</InputErrorMessage> : null}
    </StyledControlledSelect>
  );
};
