import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";

export const Wrapper = styled(StyledFlex)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(30px);
  width: 200px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  padding: 20px;
  background-color: var(--colors-white);
  z-index: 3;
`;
