import React, { FC, useState, InputHTMLAttributes } from "react";
import { NumericFormatProps, NumericFormat } from "react-number-format";
import { useField } from "formik";

import {
  Input as InputStyled,
  InputErrorMessage,
  InputWrapper,
  Label,
  InputShowPasswordIcon,
  FormatElement,
} from "./Input.styled";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  type: string;
  variant?: string;
  unitSign?: Nullable<string>;
  displayErrorMessage?: boolean;
}

export const Input: FC<InputProps> = ({
  id,
  name,
  label,
  type,
  variant,
  unitSign,
  onChange,
  value,
  displayErrorMessage = true,
  ...otherProps
}) => {
  const [field, meta] = useField(name);
  const hasError = meta.error && meta.touched ? true : false;
  const [isPasswordShown, setPasswordShown] = useState(false);

  return (
    <div>
      {label && <Label htmlFor={id || name}>{label}</Label>}
      <InputWrapper>
        {unitSign && <FormatElement>{unitSign}</FormatElement>}
        <InputStyled
          {...field}
          {...otherProps}
          hasError={hasError}
          variant={variant}
          type={type === "password" && isPasswordShown ? "text" : type}
          className={unitSign ? "withSymbol" : ""}
          value={field.value ?? value}
          id={id || name}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
        />
        {type === "password" && (
          <InputShowPasswordIcon hasError={hasError} onClick={() => setPasswordShown(!isPasswordShown)} />
        )}
      </InputWrapper>
      {displayErrorMessage && hasError ? <InputErrorMessage>{(hasError && meta.error) || ""}</InputErrorMessage> : null}
    </div>
  );
};

interface INumberInputProps extends NumericFormatProps {
  id: string;
  label: string;
}

export const NumberInput = ({ id, label, name, ...props }: INumberInputProps) => {
  const [field, meta, { setTouched, setValue }] = useField(name!);
  const hasError = meta.error && meta.touched ? true : false;

  return (
    <div>
      {label && <Label htmlFor={id || name}>{label}</Label>}
      <NumericFormat
        id={id}
        thousandSeparator={true}
        customInput={InputStyled}
        onBlur={() => setTouched(true)}
        onValueChange={(vals) => setValue(vals.floatValue)}
        value={field.value}
        {...props}
      />
      {hasError ? <InputErrorMessage>{(hasError && meta.error) || ""}</InputErrorMessage> : null}
    </div>
  );
};
