import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { skillsUrl } from "../api";
import { logError } from "../services";
import { SkillsResponse } from "../types/responses";

export const useSkills = () => {
  return useQuery("skills", () => ApiClient.get<SkillsResponse>(skillsUrl).then((response) => response.data.skills), {
    onError: (e) => logError(e),
  });
};

export default useSkills;
