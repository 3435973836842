import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const Spinner = styled.div<SpaceProps & { color?: string; width?: string; height?: string }>`
  ${space}
  & {
    border: 3px solid ${({ color }) => color || "#3498db"};
    border-top: 3px solid transparent;
    border-radius: 50%;
    width: ${({ width }) => width || "20px"};
    height: ${({ height }) => height || "20px"};
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
