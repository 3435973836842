export const WORK_TYPE_SCOPES = [
  "VEGETATION",
  "ASSET",
  "INFRINGEMENT",
  "INSPECTION",
  "PERMISSIONING",
  "HAZARD_TREE",
  "REWORK",
  "QC",
] as const;

export type WorkTypeScope = typeof WORK_TYPE_SCOPES[number];

export const WORK_TYPE_TARGET = ["SPAN", "STRUCTURE", "HAZARD_TREE"] as const;

export type WorkTypeTarget = typeof WORK_TYPE_TARGET[number];

export interface WorkTypeResponse {
  id: string;
  name: string;
  unit: string;
  defaultFlag: boolean;
  code: string;
  scope: WorkTypeScope;
  targets: WorkTypeTarget[];
  iconUrl: string;
}
