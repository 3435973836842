import { useMemo } from "react";
import { useCrewsV2 } from "../../../hooks";
import { getOptionsFromArray } from "../utils";

export const useCrewsV2Options = () => {
  const { data } = useCrewsV2();

  const options = useMemo(() => getOptionsFromArray(data, "name", "id"), [data]);

  return options;
};
