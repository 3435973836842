import React, { FC } from "react";
import { Wrapper, CloseButton } from "./Chip.styled";
import { ReactComponent as CloseIcon } from "../../assets/images/close_custom.svg";
import { StyledFlex } from "../../assets/styles/flex.styled";

interface IProps {
  value: string;
  Icon?: Nullable<FC<React.SVGProps<SVGSVGElement>>>;
  type?: string;
  onDelete: (type: string, value: string) => void;
}

export const Chip: FC<IProps> = ({ type, value, Icon, onDelete, ...props }) => {
  return (
    <Wrapper {...props}>
      {Icon ? <Icon /> : <StyledFlex height={"12px"} width={"12px"} />}
      {type && <span>{type}: </span>}
      {<span className="value">{value}</span>}
      <CloseButton Icon={CloseIcon} onClick={() => onDelete(type!, value)} type="button" />
    </Wrapper>
  );
};
