import { useQuery } from "react-query";
import { workOrderStatusesUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";
import { WorkOrderStatusItem } from "../types";

export const useWorkOrderStatuses = () => {
  return useQuery(
    "wo-status",
    () => ApiClient.get<WorkOrderStatusItem[]>(workOrderStatusesUrl).then((res) => res.data),
    {
      onError: (e) => logError(e),
    }
  );
};
