// Custom useQuery wrapper to simplify its usage
import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";

const getRequestWrapper = (url: string, params?: unknown) => async <T>() => {
  const { data } = await ApiClient.get<T>(url, params);

  return data;
};

export const useCustomQuery = <T>(url: string, options = {}, params?: unknown) =>
  useQuery<T>(url, getRequestWrapper(url, params), options);
