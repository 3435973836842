import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

import { Data2, InputLabel } from "../../assets/styles/fonts.styled";
import { ERROR_RED } from "../../constants/colors";
import { ReactComponent as ShowPasswordIcon } from "./../../assets/images/password_eye.svg";

interface InputProps extends SpaceProps {
  hasError?: boolean;
  variant?: string;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  ${space}
  ${Data2}
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  caret-color: ${(props) => props?.theme?.colors?.blueChill};
  padding: 0.375rem 0.75rem;
  border: ${(props) => (props.hasError ? "1px solid #D01E1E" : "1px solid var(--colors-ebb)")};
  :disabled {
    background-color: var(--colors-slate-100);
    color: var(--colors-slate-500);
    border-color: transparent;
    cursor: not-allowed;
  }

  &.withSymbol {
    padding-left: 1.6rem;
  }

  &:active,
  &:focus {
    border-color: var(--colors-eastern-blue);
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputShowPasswordIcon = styled(ShowPasswordIcon)<{ hasError: boolean }>`
  position: absolute;
  top: calc(50% - 9px);
  right: ${(props) => (props.hasError ? "42px" : "19px")};
`;

export const InputErrorMessage = styled.span`
  color: ${ERROR_RED};
  display: inline-block;
  width: 100%;
  text-align: end;
  font-weight: 500;
  font-size: 15px;
`;

export const Label = styled.label`
  margin-bottom: 7px;
  display: block;
  ${InputLabel}
`;

export const FormatElement = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--colors-silver);
`;
