import { ConfigType } from "../../types/responses/Crews";
import { toUsdCurrency } from "../../utils/stringUtils";
import { IRateItemDto, ISheetItem, IVectorObject, IVendor, RatesSheetResponse } from "./types";

export const getSecondLevelColumns = (id: number, vObj: IVectorObject, isTableEditable: boolean) => {
  const commonProps = {
    type: "string",
    vectorId: vObj.id,
    className: "billing-rate hasSecondLevel",
    editable: isTableEditable,
    Footer: <div></div>,
    formatInactive: (value: number) => toUsdCurrency(value.toString(), 2),
  };

  return [
    {
      id: `${id}-straight`,
      Header: `Straight time billing rate`,
      dataType: ConfigType.STRAIGHT,
      ...commonProps,
      accessor: (item: ISheetItem) => item?.dynamicValues.find((dVal) => dVal.vectorId === vObj.id)?.rate ?? 0,
    },
    {
      id: `${id}-overtime`,
      Header: `Overtime time billing rate`,
      dataType: ConfigType.OVERTIME,
      ...commonProps,
      accessor: (item: ISheetItem) => item?.dynamicValues.find((dVal) => dVal.vectorId === vObj.id)?.overtimeRate ?? 0,
    },
  ];
};

export const updateRatesData = (
  previousData: RatesSheetResponse,
  itemId: string,
  newData: { rate?: string; overtimeRate?: string }
) => {
  return {
    ...previousData,
    items: previousData.items.map((item) => {
      if (item.id === itemId) {
        return { ...item, ...newData };
      }
      return item;
    }),
    ratesSheet: previousData?.ratesSheet.map((sItem) => {
      return {
        ...sItem,
        dynamicValues: sItem.dynamicValues.map((dVal) => {
          if (dVal.id === itemId) {
            return { ...dVal, ...newData };
          }
          return dVal;
        }),
      };
    }),
  };
};

export const addRatesData = (
  previousData: RatesSheetResponse,
  addedItem: {
    rateSheetId: string;
    targetObjectId: string;
    vectorObjectId: string;
    rate?: number;
    overtimeRate?: number;
  }
) => {
  return {
    ...previousData,
    items: [
      ...previousData.items,
      {
        id: "newItem",
        rate: addedItem.rate,
        overtimeRate: addedItem.overtimeRate,
        vectorObjectId: addedItem.vectorObjectId,
        targetObjectId: addedItem.targetObjectId,
      },
    ],
    ratesSheet: previousData?.ratesSheet.map((sItem) => {
      return {
        ...sItem,
        dynamicValues: sItem.dynamicValues.map((dVal) => {
          if (sItem.id === addedItem.targetObjectId && dVal.vectorId === addedItem.vectorObjectId) {
            const newItem = {
              ...dVal,
              id: "newItem",
              rate: addedItem.rate,
              overtimeRate: addedItem.overtimeRate,
            };

            return newItem;
          }

          return dVal;
        }),
      };
    }),
  };
};

export const getVendorOptions = (vendors: IVendor[] | undefined) =>
  vendors?.map((vendor) => ({ value: vendor.name, label: vendor.name, id: vendor.id })) ?? [];

export const getItem = (items: IRateItemDto[], columnId: string, rowId: string): IRateItemDto => {
  return items.find((item) => {
    if (item.targetObjectId === rowId && item.vectorObjectId === columnId) return true;
    return false;
  })!;
};
