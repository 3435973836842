import { useQuery } from "react-query";
import { equipmentTypesUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";

export interface EquipmentDto {
  code: string | null;
  iconUrl: string | null;
  id: string;
  name: string;
}

export const useEquipmentTypes = () => {
  return useQuery(
    "equipment-types",
    () => ApiClient.get<EquipmentDto[]>(equipmentTypesUrl).then((res) => res.data as EquipmentDto[]),
    {
      onError: (e) => logError(e),
    }
  );
};
