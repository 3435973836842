import { Button, Wrapper, MenuContainer, MenuList, MenuItem } from "./TableRowMenu.styled";
import { ReactComponent as DotsVertical } from "../../../../../../assets/images/icon_dots_vertical.svg";
import { ComponentPropsWithRef, useRef, useState } from "react";
import { useClickOutside } from "../../../../../../hooks";

const TableRowMenuItem = ({ children, ...props }: ComponentPropsWithRef<"li">) => (
  <MenuItem {...props}>{children}</MenuItem>
);

type TableRowMenuProps = ComponentPropsWithRef<"div"> & {
  buttonProps?: ComponentPropsWithRef<"button">;
};

const TableRowMenu = ({ children, buttonProps, ...props }: TableRowMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, () => setIsOpen(false));

  const toggleMenu = () => setIsOpen((open) => !open);

  const handleMenuClick = () => setIsOpen(false);

  return (
    <Wrapper ref={wrapperRef} {...props}>
      <Button onClick={toggleMenu} {...buttonProps}>
        <DotsVertical />
      </Button>
      <MenuContainer>
        <MenuList visible={isOpen} onClick={handleMenuClick}>
          {children}
        </MenuList>
      </MenuContainer>
    </Wrapper>
  );
};

TableRowMenu.Item = TableRowMenuItem;

export default TableRowMenu;
