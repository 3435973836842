import styled from "styled-components";
import { position, PositionProps, padding, PaddingProps } from "styled-system";
import { Link } from "react-router-dom";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const StyledLink = styled(Link)<PaddingProps>`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  ${padding}
`;

export const VantageLogoStyled = styled.img<PositionProps>`
  ${position}
  margin: 0px;
  width: 90px;
  height: 21.48px;
`;

export const LogoWrapper = styled(StyledFlex)`
  color: #21252b;
  text-decoration: none;
  span {
    height: 22px;
  }

  .delimiter {
    color: #c4c4c4;
  }
`;
