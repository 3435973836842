import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { Button } from "../../../../components/Button";
import { TOP_HEADER_HEIGHT } from "../../../../constants/dimensions";

export const Wrapper = styled(StyledFlex)`
  padding: 0 12px;
  height: ${TOP_HEADER_HEIGHT}px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;

  > .delimiter {
    color: #c4c4c4;
    height: 22px;
    margin-left: 8px;
    padding-right: 8px;
  }
`;

export const PlanInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  > * + * {
    margin-left: 16px;
  }

  svg {
    color: #1fa7b3;
  }
`;

export const StatusBadge = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  background-color: #f3f1f1;
  color: var(--colors-river-bad);
  border-radius: 40px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  max-height: 28px;
  text-transform: capitalize;

  &[data-status="complete"] {
    background-color: #f3f1f1;
  }

  &[data-status="active"] {
    background-color: #b0d4ff;
  }
  &[data-status="past due"] {
    background-color: rgba(255, 0, 0, 0.15);
  }
  &[data-status="draft"] {
    background-color: #fbf7a3;
  }
  &[data-status="achieved"] {
    background-color: rgba(75, 81, 91, 0.37);
  }
`;

export const ExitButton = styled(Button).attrs({ variant: "secondary-text" })`
  margin-left: auto;
`;
