import { useMutation, useQueryClient } from "react-query";
import { getRateEditItemUrl } from "../../../api";
import { logError } from "../../../services";
import ApiClient from "../../../utils/apiClient";
import { RatesSheetResponse } from "../types";
import { updateRatesData } from "../utils";

interface IPutData {
  id: string;
  data: {
    rate?: string;
    overtimeRate?: string;
  };
}

const updateRateItem = (item: IPutData) => ApiClient.put(getRateEditItemUrl(item.id), { ...item.data });

export const useUpdateItemRate = (rateTypeId: string | undefined, vendorId?: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateRateItem, {
    onMutate: (item) => {
      const previousData = queryClient.getQueryData(["rates-sheet", rateTypeId, vendorId]) as RatesSheetResponse;

      const updatedSheet = updateRatesData(previousData, item.id, item.data);

      queryClient.setQueryData(["rates-sheet", rateTypeId, vendorId], updatedSheet);

      return { previousData };
    },
    onError: (err, updatedItem, context) => {
      queryClient.setQueryData(["rates-sheet", rateTypeId, vendorId], context?.previousData);
      logError(err);
    },
  });
};
